import bus from "@/core/api/bus"
import logger from "@/core/logger"
import createRequestBuilder from "./request"

export async function reportCouponGiven(campaignId: string, couponCode: string, couponUsed: boolean) {
  if (campaignId && couponCode) {
    await createRequestBuilder()
      .setCoupon({
        code: couponCode,
        campaign: campaignId,
        used: couponUsed
      })
      .send({ skipPageViews: true })
    bus.emit("coupongiven", {
      coupon_code: couponCode,
      coupon_campaign: campaignId,
      coupon_used: couponUsed
    })
    return
  }
  logger.info("No coupon info found.")
}
