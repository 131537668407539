import context from "@/core/context"
import injectCampaigns from "./injectCampaigns"
import executeScripts from "./injectScripts"
import instrumentTiming from "./sendTiming"
import { EventResponseMessage } from "@/types"
import bus from "../../bus"
import visit from "@/core/visit"

function emitPrerender(response: EventResponseMessage) {
  bus.emit("prerender", {
    customerId: visit.getCustomerId(),
    affinityScores: response.af,
    geoLocation: response.gl,
    eventDate: response.ed,
    pageViews: response.pv,
    segments: response.se
  })
}

function emitDebugdata(response: EventResponseMessage) {
  if (response.debug) {
    bus.emit("debugdata", response.debug)
  }
}

function emitServerError(response: EventResponseMessage) {
  if (response.errors && response.errors.length) {
    bus.emit("servererror", response.errors)
  }
}

export default (responseData: EventResponseMessage, render: boolean) => {
  const responseReady = new Date()
  emitDebugdata(responseData)
  emitServerError(responseData)
  emitPrerender(responseData)
  if (render) {
    context.domReady(() => injectCampaigns(responseData))
  }
  void executeScripts(responseData)
  instrumentTiming(responseData, responseReady)
}
