import OpenChain from "./OpenChain"
import AfterDelayLink from "./afterDelay"
import AfterScrollLink from "./afterScroll"
import ExitIntentLink from "./exitIntent"
import discountFeature from "./discountPopupFeature"
import settings from "@/core/settings"
import context from "@/core/context"
import windows from "@/core/windows"
import createLoader from "@/core/loaders"
import createSelector from "@/placements/selector"
import logger from "@/core/logger"
import { ConditionDTO, Effect, EventResponseMessage, Maybe, PopupTriggerSettingsDTO, maybe } from "@/types"
import { PopupEffect } from "./types"
import { toError } from "@/utils/toError"
import { PopupTrigger } from "@/core/popups/types"

type PopupCampaign = PopupTriggerSettingsDTO & { type?: PopupTrigger }

const loaders = createLoader(() => windows.nosto)
const selector = createSelector(windows.site.document, loaders)

function countObjectKeys(obj: object) {
  let key
  let i = 0
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      i += 1
    }
  }
  return i
}

function stringCompare(a: string, b: string) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

function createOverlay() {
  function nostoInParentWindow() {
    try {
      return windows.site.parent.nostojs
    } catch (e) {
      // Catch cross-origin exception if site is loaded in IFrame
      return false
    }
  }

  try {
    // Defined, to have a determinate order to list popup campaigns
    const triggerTypes = [
      "api",
      "newCustomer",
      "exitIntent",
      "allCustomers",
      "externalCampaign",
      "abandonedCart"
    ] as const

    let discountPopup: ReturnType<typeof discountFeature> | null = null
    const inIFrame = windows.site && windows.site !== windows.site.parent && nostoInParentWindow()
    if (settings.discountPopupTriggers && countObjectKeys(settings.discountPopupTriggers) > 0 && !inIFrame) {
      discountPopup = discountFeature()
    }
    // init exit intent and scroll handlers
    const openChain = new OpenChain(windows.site)
    const afterDelayLink = new AfterDelayLink(windows.site)
    const exitIntentLink = new ExitIntentLink(windows.site)
    const afterScrollLink = new AfterScrollLink(windows.site)

    if (discountPopup) {
      discountPopup.stampOnCheckoutPage()
    }

    const campaignList = () => {
      let campaigns: PopupCampaign[]
      let triggerTypeIndex: number
      let campaignIndex: number
      const allCampaigns = new Array<PopupCampaign>()
      if (!settings.discountPopupTriggers) {
        return []
      }
      for (triggerTypeIndex = 0; triggerTypeIndex < triggerTypes.length; triggerTypeIndex += 1) {
        campaigns = settings.discountPopupTriggers[triggerTypes[triggerTypeIndex]] || []
        for (campaignIndex = 0; campaignIndex < campaigns.length; campaignIndex += 1) {
          campaigns[campaignIndex].type = triggerTypes[triggerTypeIndex]
          allCampaigns.push(campaigns[campaignIndex])
        }
      }
      return allCampaigns
    }

    const lookupPopupCampaignById = (popupId: string) => {
      let key
      let campaigns
      let i
      if (!settings.discountPopupTriggers) {
        return null
      }
      for (key in settings.discountPopupTriggers) {
        if (!settings.discountPopupTriggers.hasOwnProperty(key)) {
          continue
        }
        campaigns = settings.discountPopupTriggers[key]
        for (i = 0; i < campaigns.length; i += 1) {
          if (campaigns[i].popup_id === popupId) {
            return campaigns[i]
          }
        }
      }
      return null
    }

    // Returns an object of effects (with defaults set, if missing) or an error string
    // in case of bad input
    const apiEffects = (effects: Record<string, unknown>) => {
      let value
      let effect
      const transformed: PopupEffect = {}
      for (effect in effects) {
        if (!effects.hasOwnProperty(effect)) {
          continue
        }
        value = effects[effect]
        if (effect === "overlayOpacity" || effect === "opacity_min") {
          if (typeof value !== "number" || Number.isNaN(value)) {
            return `Expected a number for effect ${effect}, got value ${value}`
          }
          if (value < 0 || value > 1.0) {
            return `Expected a number between 0 and 1.0 for effect ${effect}, got value ${value}`
          }
          transformed.opacity_min = value
        } else if (effect === "fadeInDelayMs" || effect === "fadein_min") {
          if (typeof value !== "number" || Number.isNaN(value)) {
            return `Expected a number for effect ${effect}, got value ${value}`
          }
          if (value < 0) {
            return `Expected a positive number for effect ${effect}, got value ${value}`
          }
          // @ts-expect-error wrong type
          transformed.fadein_min = parseInt(value, 10)
        } else {
          return `Unexpected effect ${effect}`
        }
      }
      return transformed
    }

    // Returns a string in case of an error
    const openPopupById = (popupId: string, opts: { preview?: boolean; effects?: Record<string, unknown> } = {}) => {
      let msg: Maybe<string> = undefined
      const popup = lookupPopupCampaignById(popupId)

      if (!popup) {
        return "Pop-up campaign not found."
      }

      const effects = apiEffects(opts.effects || {})

      if (typeof effects === "string") {
        return effects
      }

      if (opts.preview) {
        discountPopup!.previewById(popupId, effects)
        return
      }

      msg = discountPopup?.openCheck(popupId) ?? undefined
      if (msg) {
        return msg
      }
      discountPopup!.open(popupId, null, effects, "api")
    }

    const enablePopupById = (popupId: string) => {
      const popup = lookupPopupCampaignById(popupId)
      if (popup) {
        popup.condition.enabledInJs = true
      }
    }

    const disablePopupById = (popupId: string) => {
      const popup = lookupPopupCampaignById(popupId)
      if (popup) {
        popup.condition.enabledInJs = false
      }
    }

    const activate = () => {
      if (discountPopup) {
        discountPopup.openMinimized()
      }
    }

    // Priority defined by triggers for popup campaigns that have no explicit order
    const triggerPriority: Record<string, number> = {
      externalCampaign: 1,
      allCustomers: 2,
      newCustomer: 3,
      exitIntent: 4,
      abandonedCart: 5,
      api: 6
    }

    const sortedCampaignsWithType = () => {
      const campaigns = campaignList()
      campaigns.sort((a, b) => {
        let triggerComparison
        if (typeof a.ordinal !== "number" && typeof b.ordinal === "number") {
          return 1
        }
        if (typeof a.ordinal === "number" && typeof b.ordinal !== "number") {
          return -1
        }
        if (typeof a.ordinal === "number" && typeof b.ordinal === "number") {
          if (a.ordinal === b.ordinal) {
            return stringCompare(a.popup_id, b.popup_id)
          }
          return a.ordinal - b.ordinal
        }
        // eslint-disable-next-line prefer-const
        triggerComparison = triggerPriority[a.type!] - triggerPriority[b.type!]
        if (triggerComparison === 0) {
          return stringCompare(a.popup_id, b.popup_id)
        }
        return triggerComparison
      })
      return campaigns
    }

    const showCartPopup = (content: string) => {
      const tempAtc = windows.site.document.createElement("span")
      tempAtc.setAttribute("id", "NostoCartPopupParent")
      windows.site.document.body.appendChild(tempAtc)
      // Need to call this to execute containing javascript
      void selector.html(tempAtc, content)
    }

    const isCartPopupShown = () => !!windows.site.document.getElementById("NostoCartPopupParent")

    const setTriggers = (responseData: EventResponseMessage) => {
      try {
        const url = context.siteUrl

        if (responseData.cpr) {
          if (!isCartPopupShown()) {
            showCartPopup(responseData.cpr)
          }
          // @ts-expect-error can't delete optional property
          delete responseData.cpr
        }

        if (settings && settings.discountPopupTriggers && discountPopup) {
          if (responseData.cdc) {
            discountPopup.done(responseData.cdc)
          }

          let i
          const setupChain = (effect: Effect, isExitIntent: boolean) => {
            const chainConfig = {
              reEntryTolerance: maybe<number>(),
              delay: maybe<number>(),
              links: new Array<OpenChain>()
            }
            if (isExitIntent) {
              chainConfig.links.push(afterDelayLink)
              chainConfig.links.push(exitIntentLink)
              if (effect) {
                chainConfig.reEntryTolerance = effect.re_entry_tolerance || 30
                chainConfig.delay = effect.delay_min || 3000
              } else {
                // Backwards compatibility of campaigns not having effects defined
                chainConfig.reEntryTolerance = 30
                chainConfig.delay = 3000
              }
            } else if (effect && effect.delay_min) {
              chainConfig.links.push(afterDelayLink)
              chainConfig.delay = effect.delay_min
            }
            if (effect) {
              if (effect.scroll_min) {
                chainConfig.links.unshift(afterScrollLink)
                // @ts-expect-error TS(2339) FIXME: Property 'scroll' does not exist on type '{ links:... Remove this comment to see the full error message
                chainConfig.scroll = effect.scroll_min
              }
            }
            return chainConfig
          }

          const campaigns = sortedCampaignsWithType()
          for (i = 0; i < campaigns.length; i += 1) {
            const popupId = campaigns[i].popup_id
            let { condition } = campaigns[i]
            if (!condition) {
              campaigns[i].condition = condition = {} as ConditionDTO
            }
            const { effect } = campaigns[i]
            const trigger = campaigns[i].type
            if (trigger === "api") {
              continue
            }
            const nosto_dp = url?.searchParams?.get("nosto_dp")
            const nostodp = url?.searchParams?.get("nostodp")
            if (
              trigger === "externalCampaign" &&
              (!url ||
                !url.searchParams ||
                (!nosto_dp && !nostodp) ||
                (nosto_dp && nosto_dp !== campaigns[i].id) ||
                (nostodp && nostodp !== campaigns[i].id))
            ) {
              continue
            }
            if (trigger === "newCustomer" && !responseData.nc) {
              continue
            }
            if (trigger === "abandonedCart" && (!responseData.hiic || responseData.he)) {
              continue
            }
            if (!discountPopup.okToOpen(popupId, condition, responseData)) {
              continue
            }
            if (!campaigns[i].enabled) {
              condition.enabled = false
            }

            openChain.register(
              discountPopup.okToOpen,
              setupChain(effect, trigger === "exitIntent" || trigger === "abandonedCart"),
              discountPopup.open,
              popupId,
              condition,
              responseData,
              // @ts-expect-error type mismatch
              effect,
              trigger
            )
          }
        }
      } catch (e) {
        logger.warn("Error setting triggers", e)
        throw toError(e)
      }
    }

    return {
      // Expose for tests
      sortedCampaignsWithType,
      activate,
      campaignList,
      openPopup: openPopupById,
      enablePopup: enablePopupById,
      disablePopup: disablePopupById,
      setTriggers,
      discountPopup: {
        instance: discountPopup,
        preview(popupId: string, campaignId: string, effect?: PopupEffect) {
          const feature = discountFeature()
          feature.preview(popupId, campaignId, effect)
          return feature
        }
      }
    }
  } catch (e) {
    logger.warn("Error creating overlay", e)
    return {
      activate() {},
      campaignList(): (PopupTriggerSettingsDTO & { type?: PopupTrigger })[] {
        return []
      },
      openPopup() {},
      enablePopup() {},
      disablePopup() {},
      setTriggers() {},
      discountPopup: {
        instance: {},
        preview() {}
      }
    }
  }
}

export default createOverlay

/**
 * @hidden
 */
export type Overlay = ReturnType<typeof createOverlay>
