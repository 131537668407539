import { watchProperties } from "@/utils/object"
import { deepClone } from "@/utils/deepClone"
import context from "@/core/context"
import { findForcedSegments } from "@/core/tagging/extract"
import createRequestBuilder, { RequestBuilder } from "@/core/request"
import trackOrder from "@/order/track"
import filterContext from "@/core/filters/filterContext"
import createActionResponse from "./response"
import { Action } from "./types"
import { RenderMode } from "@/types"
import { TaggingData, Product } from "@/core/tagging/types"
import { assertValid } from "@/core/tagging/validation"

function normalizeProduct(product: string | Product): Product {
  if (typeof product === "string" || typeof product === "number") {
    return { product_id: String(product) }
  } else {
    return product ?? {}
  }
}

interface ExtendedAction extends Action {
  // internal method exposed for testing
  createRequest(): RequestBuilder
}

function createAction(staticData: TaggingData, responseMode: RenderMode): ExtendedAction {
  const data = watchProperties(staticData, (k, v) => filterContext.update({ [k]: v }))
  const refs: Record<string, string> = {}

  return {
    setRef(productId, reference) {
      refs[productId] = reference
      return this
    },
    setProduct(product) {
      return this.setProducts([product])
    },
    setProducts(products) {
      data.products = products.map(normalizeProduct)
      return this
    },
    setCart(cart) {
      data.cart = cart
      return this
    },
    setCustomer(customer) {
      data.customer = customer
      return this
    },
    setOrder(order) {
      data.order = order
      return this
    },
    setSearchTerms(searchTerms) {
      data.searchTerms = searchTerms
      return this
    },
    setCategories(categories) {
      data.categories = categories
      return this
    },
    setCategoryIds(categoryIds) {
      data.categoryIds = categoryIds
      return this
    },
    setParentCategoryIds(parentCategoryIds) {
      data.parentCategoryIds = parentCategoryIds
      return this
    },
    setTags(tags) {
      data.tags = tags
      return this
    },
    setCustomFields(customFields) {
      data.customFields = customFields
      return this
    },
    setVariation(variation) {
      data.variation = variation
      return this
    },
    setPlacements(placements) {
      data.elements = placements.filter(n => n)
      return this
    },
    setRestoreLink(restoreLink) {
      data.restoreLink = restoreLink
      return this
    },
    setPageType(pageType) {
      data.pageType = pageType
      return this
    },
    addAffinity(affinity, options) {
      if (options?.clear) {
        data.affinitySignals = {}
      }
      data.affinitySignals = { ...data.affinitySignals, ...affinity }
      return this
    },
    dumpData() {
      return deepClone(data)
    },
    update() {
      return this.load({ skipPageViews: true })
    },
    createRequest() {
      const request = createRequestBuilder().disableCampaignInjection().setResponseMode(responseMode)

      const forcedSegments = findForcedSegments() || []
      request.populateFrom({
        data,
        forcedSegments
      })

      if (!context.mode.isPreview()) {
        request.setRefs(refs)
      }
      return request
    },
    async load(flags) {
      assertValid(data)
      const response = await this.createRequest().load(flags)
      if (data.order) {
        void trackOrder(data.order)
      }
      return createActionResponse(response, responseMode)
    }
  }
}

export default createAction
