import text from "./text"

type Schema<T> = { [K in keyof T]: Mapper<T[K] | undefined> }
type Mapper<T> = (root: ParentNode) => T

function selectAll(node: ParentNode, selector: string) {
  return Array.from(node.querySelectorAll<HTMLElement>(selector))
}

export function schemaFn<T extends object>(obj: Schema<T>) {
  return (node: ParentNode) => {
    return Object.keys(obj).reduce((data, key) => {
      const v = obj[key](node)
      if (v !== undefined) {
        data[key] = v
      }
      return data
    }, {} as T)
  }
}

export function children<T>(selector: string, mapper: Mapper<T>): Mapper<T[]> {
  return (node: ParentNode) => {
    return selectAll(node, selector).map(mapper)
  }
}

export function select<T extends string = string>(selector: string): Mapper<T>
export function select<T>(selector: string, valueMapper: (e: HTMLElement | undefined) => T): Mapper<T>

export function select<T>(selector: string, valueMapper: (e: HTMLElement | undefined) => unknown = text): Mapper<T> {
  return (node: ParentNode) => {
    const element = node.querySelector<HTMLElement>(selector) ?? undefined
    return valueMapper(element) as T
  }
}

const texts = (els: HTMLElement[]) => els.map(text)

export function selectMulti<T = string[]>(selector: string): Mapper<T>
export function selectMulti<T = string[]>(selector: string, valueMapper: (els: HTMLElement[]) => T): Mapper<T>

export function selectMulti<T = string>(
  selector: string,
  valueMapper: (els: HTMLElement[]) => unknown = texts
): Mapper<T> {
  return (node: ParentNode) => {
    const elements = selectAll(node, selector)
    return valueMapper(elements) as T
  }
}

export function keyval(selector: string) {
  return (node: ParentNode) => {
    const elements = selectAll(node, selector)
    return elements.reduce<Record<string, string>>((map, element) => {
      map[element.className] = text(element)!
      return map
    }, {})
  }
}
