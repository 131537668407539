import { EventResponseMessage, Maybe } from "@/types"
import windows from "./windows"
import bus from "./api/bus"

type GoogleAnalytics4 = (action: string, eventName: string, eventData: { segment_id: string }) => void
type GTM = { event: string; segment_id: string }[]

function nostoGetGA4(target: Window): Maybe<GoogleAnalytics4> {
  // @ts-expect-error unknown field
  return target.gtag
}

function nostoGetDataLayer(target: Window): Maybe<GTM> {
  // @ts-expect-error unknown field
  return target.dataLayer && Array.isArray(target.dataLayer) ? target.dataLayer : undefined
}

export function sendSegments(data: EventResponseMessage) {
  const googleAnalytics = data ? data.ga : undefined
  if (!googleAnalytics || !googleAnalytics.s) {
    return
  }

  const ga4 = nostoGetGA4(windows.nosto) || nostoGetGA4(windows.site)
  const dataLayer = nostoGetDataLayer(windows.nosto) || nostoGetDataLayer(windows.site)
  const segments = googleAnalytics.s
  const eventName = "nostoSegment"

  if (typeof ga4 === "function") {
    segments.forEach(segment =>
      ga4("event", eventName, {
        segment_id: segment
      })
    )
  } else if (dataLayer) {
    segments.forEach(segment =>
      dataLayer.push({
        event: "nostoSegment",
        segment_id: segment
      })
    )
  }
}

export function initGoogleAnalytics() {
  bus.on("taggingsent", sendSegments)
}
