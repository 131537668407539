import logger from "@/core/logger"
import settings from "./settings"
import windows from "./windows"
import { toError } from "@/utils/toError"

const { performance } = windows.site

type ValidName = `nosto.${string}`

const noop = () => {}

export function startMeasuringPerformance(name: ValidName) {
  performance.mark(`${name}.start`)

  return () => {
    performance.mark(`${name}.end`)
    performance.measure(`${name}`, `${name}.start`, `${name}.end`)
  }
}

let startMeasuring: (name: ValidName) => () => void
if (settings.measurePerformance) {
  if (
    typeof performance === "object" &&
    typeof performance.mark === "function" &&
    typeof performance.measure === "function"
  ) {
    startMeasuring = startMeasuringPerformance
  } else {
    startMeasuring = () => {
      logger.debug("Browser doesn't support user timing API.")
      return noop
    }
  }
} else {
  startMeasuring = () => {
    logger.debug("Performance measuring not enabled.")
    return noop
  }
}

async function handlePromise<T>(promise: Promise<T>, end: () => void) {
  try {
    const result = await promise
    end()
    return result
  } catch (e) {
    end()
    throw toError(e)
  }
}

/**
 * Measure the performance of some code.
 * @param {String} name The name of the measure to be recorded.
 * @param {Function} callback the code to measure
 * @return {Object} The result returned by callback
 */
export default function measure<T>(name: ValidName, callback: () => T): T {
  if (!settings.measurePerformance) {
    return callback()
  }
  const end = startMeasuring(name)

  let result
  try {
    result = callback()
  } catch (e) {
    end()
    throw toError(e)
  }

  // detect and handle promise
  if (result instanceof Promise) {
    void handlePromise(result, end)
  } else {
    end()
  }

  return result
}
