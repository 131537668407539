import context from "@/core/context"
import unsubscribePanel from "./unsubscribePanel"

let unsubPanelLoaded = false

export function isLoaded() {
  return unsubPanelLoaded
}

export async function load() {
  if (unsubPanelLoaded) {
    return
  }

  unsubPanelLoaded = true
  await unsubscribePanel()
}

export async function initUnsubscribePanel() {
  if (context.siteUrl.hash && context.siteUrl.hash.indexOf("2CSettings-") === 0) {
    await load()
  }
}
