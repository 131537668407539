import settings from "@/core/settings"
import { EventResponseMessage } from "@/types"
import { ActionResponse } from "./types"
import { filterObjectKeys } from "@/utils/object"

interface RecommendationResult {
  result_id: string
  source_product_ids?: string[]
}

function createActionResponse(
  { cmpid, recommendations, gl, pv, af }: EventResponseMessage,
  responseMode: string
): ActionResponse {
  if (responseMode === "HTML") {
    return {
      recommendations,
      page_views: pv,
      geo_location: gl,
      affinities: af,
      cmpid
    }
  } else {
    // add params field to each recommendation result object
    const recs: Record<string, Record<string, unknown>> = {}
    Object.keys(recommendations || {}).forEach(key => {
      const value = recommendations[key] as RecommendationResult
      // build params object (typically nostoRefParam is "nosto", result_id is the rec/content campaign id that is shown )
      const params = { [settings.nostoRefParam]: value.result_id }
      // if the recommendation was related products or cart related, add another param to identify what were the source pids
      if (value.source_product_ids?.length) {
        params.refSrc = value.source_product_ids.join(",")
      }
      // build a new object with params as additional attribute
      /* jshint ignore:start */
      recs[key] = {
        ...value,
        params
      }
      /* jshint ignore:end */
    })

    const campaigns = {
      /* group by campaign type based on defined fields, content has html and recommendations has products */
      recommendations: filterObjectKeys(recs, key => recs[key].products),
      content: filterObjectKeys(recs, key => recs[key].html)
    }

    return {
      recommendations: recs,
      campaigns,
      page_views: pv,
      geo_location: gl,
      affinities: af,
      cmpid
    }
  }
}

export default createActionResponse
