import { AxiosInstance, AxiosRequestConfig } from "axios"
import settings from "@/core/settings"
import visit from "@/core/visit"

export function modifyConfig(config: AxiosRequestConfig<unknown>) {
  if (config.url?.startsWith(`${settings.server}/ev1`)) {
    const url = new URL(config.url)
    const customerId = visit.getCustomerId()
    if (!url.searchParams.has("c") && customerId) {
      url.searchParams.append("c", customerId)
      return { ...config, url: url.toString() }
    }
  }
  return config
}

/**
 * Adds customer parameter to ev1 request in case it's not already present
 * Useful for initial queued requests that are not yet aware of the customer
 */
export default function addCustomer(axios: AxiosInstance) {
  axios.interceptors.request.use(modifyConfig)
}
