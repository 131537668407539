import { Maybe } from "@/types"
import { ParseUriResult } from "@/utils/parseuri"
import { EXTERNAL_SEARCH } from "./event"

export function refererEvent(uri: ParseUriResult) {
  try {
    let keyWord: Maybe<string>
    if (uri.hostname.match(/google/i) || uri.hostname.match(/bing/i)) {
      keyWord = uri.searchParams.get("q") ?? undefined
    } else if (uri.hostname.match(/yahoo/i)) {
      keyWord = uri.searchParams.get("p") ?? undefined
    }

    if (keyWord) {
      // + don't seem to be transformed to spaces with
      return { type: EXTERNAL_SEARCH, target: keyWord } as const
    }
  } catch (error) {
    // do nothing
  }
}
