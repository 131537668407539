import page from "@/core/page"
import parsePrice from "./utils/price"
import { select, schemaFn, children } from "./utils/schema"
import { ConversionItem, WebsiteOrder } from "@/types"
import toInt from "./utils/toInt"
import text from "./utils/text"
import pipe from "@/utils/pipe"
import defaultValue from "./utils/defaultValue"
import defaultText from "./utils/defaultText"

const orderItemFn = schemaFn<ConversionItem>({
  product_id: select(".product_id"),
  sku_id: select(".sku_id"),
  name: select(".name"),
  quantity: select(".quantity", pipe(text, toInt)),
  unit_price: select(".unit_price", pipe(text, parsePrice)),
  price_currency_code: select(".price_currency_code")
})

const newsletterFn = (v: string | undefined) =>
  (v !== undefined ? String(["1", "true"].includes(v)) : undefined) as string

const orderFn = schemaFn<WebsiteOrder & { hcid: string }>({
  payment_provider: select(".nosto_purchase_order > .payment_provider"),
  external_order_ref: select(".nosto_purchase_order > .external_order_ref"),
  hcid: select(".nosto_purchase_order > .hcid", pipe(text, defaultText("blank"))),
  order_status: select(".nosto_purchase_order > .order_status_code"),
  info: schemaFn({
    order_number: select(".nosto_purchase_order > .order_number"),
    type: select(".nosto_purchase_order > .type", pipe(text, defaultValue("order"))),
    email: select(".nosto_purchase_order > .buyer > .email"),
    first_name: select(".nosto_purchase_order > .buyer > .first_name"),
    last_name: select(".nosto_purchase_order > .buyer > .last_name"),
    country: select(".nosto_purchase_order > .buyer > .country"),
    phone: select(".nosto_purchase_order > .buyer > .phone"),
    post_code: select(".nosto_purchase_order > .buyer > .post_code"),
    newsletter: select(".nosto_purchase_order > .buyer > .marketing_permission", pipe(text, newsletterFn))
  }),
  items: children(".nosto_purchase_order .purchased_items .line_item", orderItemFn)
})

export default function findOrder() {
  const orderDiv = page.select(".nosto_purchase_order")
  if (!orderDiv) {
    return undefined
  }

  return orderFn(orderDiv) as WebsiteOrder
}
