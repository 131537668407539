import settings from "@/core/settings"
import windows from "@/core/windows"

function loadStacklaTracking(trackingUrl: string) {
  const { document } = windows.site
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.src = trackingUrl
  // Insert the script tag as the first script of the page
  const firstScriptTag = document.querySelector("script")
  firstScriptTag!.parentNode!.insertBefore(script, firstScriptTag)
}

export default function load() {
  if (settings.stacklaTrackingUrl == null) {
    return
  }
  loadStacklaTracking(settings.stacklaTrackingUrl)
}
