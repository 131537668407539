import settings from "@/core/settings"
import axios from "axios"
import { sessionStore } from "./store"
import bus from "./api/bus"
import logger from "./logger"

const key = "nosto:analytics:queue"

type Data = {
  url: string
  data: object
}

type Flags = { delayed: boolean }

/**
 * Sends analytics data to the specified URL.
 */
export async function send(url: string, data: object, flags?: Flags) {
  if (!settings.testing && flags?.delayed) {
    enqueue({ url, data })
  } else {
    await axios.post(url, data)
  }
}

/**
 * Sends all queued analytics data by making HTTP POST requests.
 */
export async function flushQueue() {
  let data: Data | undefined
  while ((data = dequeue())) {
    try {
      await axios.post(data.url, data.data)
    } catch (e) {
      logger.warn("Failed to send analytics data", e)
    }
  }
}

/**
 * Initializes the analytics module.
 */
export function initAnalytics() {
  if (!settings.testing) {
    bus.on("prerequest", flushQueue)
  }
}

function enqueue(data: Data) {
  const queue = sessionStore.getAsJson<Data[]>(key) || []
  queue.push(data)
  sessionStore.setAsJson(key, queue)
}

function dequeue() {
  const queue = sessionStore.getAsJson<Data[]>(key) || []
  if (queue.length) {
    const data = queue.shift()
    sessionStore.setAsJson(key, queue)
    return data
  }
}
