import bus from "@/core/api/bus"
import logger from "@/core/logger"
import createRequestBuilder from "./request"
import { PushedCustomer } from "@/types"

export async function setCustomer(customer: PushedCustomer) {
  if (customer) {
    await createRequestBuilder().setCustomer(customer).send({ skipPageViews: true })
    bus.emit("emailgiven", customer)
    return
  }
  logger.info("No customer info found.")
}
