import parseUri from "@/utils/parseuri"
import context from "@/core/context"
import page from "@/core/page"
import windows from "@/core/windows"
import logger from "@/core/logger"

export default function linkify() {
  logger.warn(
    "The product tagging is missing the URL field and therefore the canonical URL of the page will be used in lieu. We recommend that you tag the URL in order to prevent potential side effects. Read more at https://docs.nosto.com/techdocs/implementing-nosto/implement-on-your-website/manual-implementation/product-tagging",
    { local: true }
  )
  let i
  let canonicalLink
  const links = page.selectAll<HTMLLinkElement>("link")
  for (i = 0; i < links.length; i += 1) {
    if (links[i].rel && links[i].rel.toLowerCase() === "canonical" && links[i].getAttribute("href")) {
      const source = links[i].getAttribute("href")
      if (!source) {
        return undefined
      }
      canonicalLink = parseUri(source)
      if (!canonicalLink) {
        return undefined
      }
      if (!canonicalLink.protocol && context.siteUrl) {
        return `${context.siteUrl.protocol}:${source}`
      }
      return source
    }
  }
  return windows.site.location.href
}
