import logger from "@/core/logger"

export default function toFloat(text: string | undefined) {
  if (text) {
    try {
      const val = parseFloat(text)
      return isNaN(val) ? undefined : val
    } catch (e) {
      logger.warn(`Failed to parse float: ${text}`, e)
      return undefined
    }
  }
  return undefined
}
