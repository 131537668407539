import { Equals, Expect } from "@/types"

export const VIEW_PRODUCT = "vp"
export const LIKE_PRODUCT = "lp"
export const DISLIKE_PRODUCT = "dp"
export const REMOVE_PRODUCT = "rp"
export const BOUGHT_PRODUCT = "bp"
export const VIEW_CATEGORY = "vc"
export const ORDER = "or"
export const INTERNAL_SEARCH = "is"
export const ADD_TO_CART = "cp"
export const EXTERNAL_CAMPAIGN = "ec"
export const EXTERNAL_SEARCH = "es"
export const GIVE_COUPON = "gc"
export const SOURCE = "src"
export const CART_POPUP_RECOMMENDATIONS = "cpr"
export const PAGE_LOAD = "pl"
export const CUSTOM_CAMPAIGN = "cc"
export const CONTENT_CAMPAIGN = "con"

const eventTypes = [
  VIEW_PRODUCT,
  LIKE_PRODUCT,
  DISLIKE_PRODUCT,
  REMOVE_PRODUCT,
  BOUGHT_PRODUCT,
  VIEW_CATEGORY,
  ORDER,
  INTERNAL_SEARCH,
  ADD_TO_CART,
  EXTERNAL_CAMPAIGN,
  EXTERNAL_SEARCH,
  GIVE_COUPON,
  SOURCE,
  CART_POPUP_RECOMMENDATIONS,
  PAGE_LOAD,
  CUSTOM_CAMPAIGN,
  CONTENT_CAMPAIGN
] as const

export const TRIGGERED_MAIL = "email"
export const EMAIL_WIDGET = "imgrec"
export const ONSITE_RECOMMENDATIONS = "rec"
export const API_RECOMMENDATIONS = "api"
export const ONSITE_CAMPAIGNS = "oc"
export const CATEGORY_MERCHANDISING = "cmp"
export const ONSITE_SEARCH = "os"

const refTypes = [
  TRIGGERED_MAIL,
  EMAIL_WIDGET,
  ONSITE_RECOMMENDATIONS,
  API_RECOMMENDATIONS,
  ONSITE_CAMPAIGNS,
  CATEGORY_MERCHANDISING,
  ONSITE_SEARCH
] as const

/**
 * @group Core
 */
export type EventType =
  /**  view product */
  | "vp"
  /** like product */
  | "lp"
  /** dislike product */
  | "dp"
  /** remove product */
  | "rp"
  /** bought product */
  | "bp"
  /** view category */
  | "vc"
  /** order */
  | "or"
  /** internal search */
  | "is"
  /** add to cart */
  | "cp"
  /** external campaign */
  | "ec"
  /** external search */
  | "es"
  /** give coupon */
  | "gc"
  /** source */
  | "src"
  /** cart popup recommendations */
  | "cpr"
  /** page load */
  | "pl"
  /** custom campaign */
  | "cc"
  /** content campaign */
  | "con"

/**
 * @group Core
 */
export type EventRefType =
  /** triggered mail */
  | "email"
  /** email widgets */
  | "imgrec"
  /** onsite recommendations */
  | "rec"
  /** api recommendations */
  | "api"
  /** onsite campaigns */
  | "oc"
  /** category merchandising */
  | "cmp"
  /** onsite search */
  | "os"

/** @hidden */
export type tests = [
  Expect<Equals<EventType, (typeof eventTypes)[number]>>,
  Expect<Equals<EventRefType, (typeof refTypes)[number]>>
]

/**
 * @group Core
 */
export type EventTuple = [
  type: EventType,
  target?: string,
  ref?: string,
  refSrc?: string,
  targetFragment?: string,
  refType?: EventRefType
]

/**
 * @group Core
 */
export interface Event {
  type: EventType
  target?: string
  ref?: string
  refSrc?: string
  targetFragment?: string
  refType?: EventRefType
}

function normalizeEventType(type: EventType) {
  return eventTypes.find(t => t === type.toLowerCase()) ?? type
}

function normalizeEventRefType(refType: EventRefType): EventRefType {
  return refTypes.find(t => t === refType.toLowerCase()) ?? refType
}

/**
 * Accepts an event object and converts it into {@link EventTuple}
 */
export function toEventTuple(event: Event): EventTuple {
  const { type, target, ref, refSrc, targetFragment, refType } = event
  const tuple: EventTuple = [
    normalizeEventType(type),
    target,
    ref,
    refSrc,
    targetFragment,
    refType ? normalizeEventRefType(refType) : undefined
  ]
  while (tuple.length > 1 && tuple[tuple.length - 1] === undefined) {
    tuple.pop()
  }
  return tuple
}

/**
 * Removes any properties from event that's not assigned a value
 */
export function cleanupEvent(event: Event): Event {
  const cleaned = Object.fromEntries(Object.entries(event).filter(([_, value]) => value !== undefined))
  return {
    type: event.type,
    ...cleaned
  }
}

/**
 * Checks if the provided event type is valid
 * @param value the event type in string form
 */
export function isEventType(value: string): value is EventType {
  return eventTypes.includes(value.toLowerCase())
}

export function isEventRefType(value: string): value is EventRefType {
  return refTypes.includes(value.toLowerCase())
}
