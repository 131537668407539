import placements from "@/placements"
import bus, { Postrender } from "@/core/api/bus"
import { EventResponseMessage } from "@/types"

export default function (response: EventResponseMessage) {
  const endEvent: Postrender = {
    responseData: response.recommendations,
    filledElements: [],
    unFilledElements: []
  }
  // processedRecommendations are recommendations processed by merchant after nosto initially rendered them.
  const campaigns = response.processedRecommendations || response.recommendations
  // @ts-expect-error type mismatch
  const result = placements.injectCampaigns(campaigns)
  endEvent.filledElements = result.filledElements
  endEvent.unFilledElements = result.unFilledElements

  bus.emit("postrender", endEvent)
}
