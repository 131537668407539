import logger from "@/core/logger"
import { pageTagging } from "@/core/tagging"
import { TaggingData } from "./tagging/types"

export default (data?: TaggingData) => {
  const tagging = data || pageTagging()
  if (tagging.pageType) {
    return tagging.pageType.toLowerCase()
  } else {
    if (tagging.products?.length) {
      return "product"
    }
    if (tagging.order) {
      return "order"
    }
    if (tagging.searchTerms?.length) {
      return "search"
    }
    if (tagging.categories?.length) {
      return "category"
    }
    logger.log(
      "" +
        "Unable to deduce the page-type for the current request. " +
        "We recommend that you tag the page-type in your DOM. " +
        "For more information on how to tag page-types, see " +
        "https://docs.nosto.com/techdocs/implementing-nosto/implement-on-your-website/manual-implementation/tag-your-page-types."
    )
    return null
  }
}
