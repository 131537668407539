import axios, { AxiosResponse } from "axios"
import handler from "./handler"
import settings from "../settings"
import visit from "../visit"
import { EventRequestMessageV1 } from "@/types"
import { RecommendationRequestFlags } from "../request"
import { deepEquals } from "@/utils/deepEquals"
import { deepClone } from "@/utils/deepClone"

/**
 * Append flags to the url
 */
export function prunedUrl(flags: RecommendationRequestFlags) {
  const url = new URL(`${settings.server}/ev1`)
  const customerId = visit.getCustomerId()
  if (customerId) {
    url.searchParams.append("c", customerId)
  }
  url.searchParams.append("m", settings.account)
  if (flags.skipPageViews) {
    url.searchParams.append("skipPageViews", "true")
  }
  if (flags.skipEvents) {
    url.searchParams.append("skipEvents", "true")
  }
  if (flags.reloadCart) {
    url.searchParams.append("reloadCart", "true")
  }
  return url.toString()
}

type Ev1Handler = (data: EventRequestMessageV1, flags?: RecommendationRequestFlags) => Promise<AxiosResponse>

/**
 * In a sequence of ev1 requests for the same page url only the first one is considered a page view.
 * This should work for
 * - server side renderered shops
 * - SPAs that utilize the History API
 * but might cause issues for
 * - SPAs without dynamic urls
 * - SPAs with hash based url state
 */
function withPageViewsHandler(fn: Ev1Handler): Ev1Handler {
  let lastUrl: string | undefined

  return async (data, flags = {}) => {
    if (typeof flags === "object" && window.location.href === lastUrl) {
      flags.skipPageViews = true
    }
    lastUrl = window.location.href
    return await fn(data, flags)
  }
}

/**
 * Cache the response of the last request if the data has not changed.
 */
function withCachingHandler(fn: Ev1Handler): Ev1Handler {
  let lastData: EventRequestMessageV1 | undefined
  let lastResponse: AxiosResponse | undefined

  return async (data, flags = {}) => {
    // return previous response if data has not changed
    if (lastResponse && deepEquals(data, lastData)) {
      return lastResponse
    }
    lastData = deepClone(data)
    lastResponse = await fn(data, flags)
    return lastResponse
  }
}

/**
 * Performs a post request which matches the specifications simple requests format to avoid preflight requests.
 * For this reason content-type is fixed to text/plain.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
 */
const coreHandler: Ev1Handler = (data, flags = {}) => {
  const baseUrl = prunedUrl(flags)
  const { url, method } = handler(baseUrl, data)

  return axios({
    url,
    data,
    method,
    headers: { "Content-Type": "text/plain" }
  })
}

export default withCachingHandler(withPageViewsHandler(coreHandler))
