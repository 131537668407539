import context from "@/core/context"
import settings from "./settings"
import windows from "./windows"

let toolBarLoaded = false

export function isLoaded() {
  return toolBarLoaded
}

export async function load() {
  const { document } = windows.site
  if (toolBarLoaded) {
    return
  }
  const url = `${settings.server}/assets/public/jsbuild/toolbar/debugtoolbar.min.js?z=!CACHEVALUE!`
  const scriptElement = document.createElement("script")
  scriptElement.src = url
  scriptElement.type = "module"
  document.body.prepend(scriptElement)
  toolBarLoaded = true
}

export async function initToolbar() {
  if (context.mode.isDebug()) {
    await load()
  }
}
