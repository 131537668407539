export function isExtractableScript(script: HTMLScriptElement) {
  return !script.type || script.type.toLowerCase().startsWith("text/javascript")
}

export function cloneScript(doc: Document, script: HTMLScriptElement) {
  const newScript = doc.createElement("script")
  newScript.type = script.type
  newScript.text = script.text
  if (script.nonce) {
    newScript.nonce = script.nonce
  }
  // copy attributes
  if (script.hasAttributes()) {
    Array.from(script.attributes).forEach(attr => {
      newScript.setAttribute(attr.name, attr.value)
    })
  }
  return newScript
}
