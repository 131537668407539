import { Token } from "./parser"

export function stringify({ type, name, value, children }: Token): string {
  switch (type) {
    case "at-rule":
      return children ? `${value} { ${children.map(stringify).join("")} }` : `${value};`
    case "property":
      return `${name}:${value};`
    case "selector":
      return `${value} { ${children!.map(stringify).join("")} }`
  }
}
