import axios from "axios"
import settings from "@/core/settings"
import logger from "@/core/logger"
import bus from "@/core/api/bus"
import visit from "@/core/visit"
import processBrowserQueue from "@/core/browserQueue"
import { WebsiteOrder } from "@/types"
import { isAxiosError } from "@/utils/error"

export interface Violation {
  key: string
  message_key: string
}

export interface ErrorResponse {
  errors: Violation[]
}

export interface OrderError {
  payload: WebsiteOrder
  response: ErrorResponse
}

export default async function track(order: WebsiteOrder) {
  try {
    await axios.post(`${settings.server}/order/track`, order, {
      params: {
        merchant: settings.account,
        c: visit.getCustomerId()
      },
      headers: {
        "Content-Type": "text/plain"
      }
    })
  } catch (err) {
    if (isAxiosError<ErrorResponse>(err) && err.response!.status === 400) {
      if (err.response!.data.errors) {
        const rows = [
          "The current order tagged is invalid. These are the validation errors that must be rectified.",
          "For more information on how to tag the orders, see",
          "https://docs.nosto.com/techdocs/implementing-nosto/implement-on-your-website/manual-implementation/order-tagging"
        ]
        err.response!.data.errors.forEach((violation: Violation) => {
          const { key, message_key } = violation
          rows.push(`• ${key}: ${message_key}`)
        })
        const message = rows.join("\n")
        logger.info(message)
        bus.emit("ordererror", { payload: order, response: err.response!.data })
        bus.emit("servererror", [message])
        return
      }
    }
    logger.error("Failed to track order", err)
  }
  await processBrowserQueue()
}
