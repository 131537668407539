import axios from "axios"
import context from "@/core/context"
import logger from "@/core/logger"
import settings from "@/core/settings"
import bus from "@/core/api/bus"
import visit from "@/core/visit"
import findProducts from "@/metadata/product/product"
import { sessionStore } from "@/core/store"
import { isAxiosError } from "@/utils/error"

interface Violation {
  key: string
  message_key: string
}

interface ErrorResponse {
  errors: Violation[]
}

export function initProductHandling() {
  if (settings.fullTaggingRequired) {
    bus.on("taggingsent", push)
  } else {
    logger.debug("Skipping product change detection as the feature is disabled.")
  }
}

async function push() {
  if (!visit.getCustomerId()) {
    if (!visit.isDoNotTrack()) {
      logger.warn("Skipping product change detection there is no session.")
    }
  } else {
    const data = findProducts()
    if (data && data.length === 1) {
      if (sessionStore.get(`nosto:product:push${data[0].product_id}`)) {
        logger.debug("Not sending duplicate product push to Nosto")
      } else {
        sessionStore.set(`nosto:product:push${data[0].product_id}`, JSON.stringify(data))

        try {
          const response = await axios.post(`${settings.server}/product/push`, data[0], {
            params: {
              merchant: settings.account,
              c: visit.getCustomerId()
            },
            headers: {
              "Content-Type": "text/plain"
            }
          })
          if (context.mode.isDebug()) {
            console.groupCollapsed?.("Validating the current product information...")
            // @ts-expect-error TS(2339) FIXME: Property 'messages' does not exist on type 'unknow... Remove this comment to see the full error message
            response.data.messages.forEach(message => {
              logger.debug(message)
            })
            console.groupEnd?.()
          }
        } catch (err) {
          if (isAxiosError<ErrorResponse>(err) && err.response!.status === 400) {
            if (err.response!.data.errors) {
              const rows = [
                "The current product tagged is invalid. These are the validation errors that must be rectified.",
                "For more information on how to tag the products, see",
                "https://docs.nosto.com/techdocs/implementing-nosto/implement-on-your-website/manual-implementation/product-tagging"
              ]
              err.response!.data.errors.forEach((violation: Violation) => {
                const { key, message_key } = violation
                rows.push(`• ${key}: ${message_key}`)
              })
              const message = rows.join("\n")
              logger.info(message)
              bus.emit("servererror", [message])
              return
            }
          }
          logger.error("Failed to push product", err)
        }
      }
    }
  }
}
