import bus from "@/core/api/bus"
import windows from "@/core/windows"
import { EventResponseMessage } from "@/types"

function createImage(name: string, data: unknown) {
  const { document } = windows.site
  const pixelCode = document.createElement("img")

  // @ts-expect-error unsafe assignment
  const searchParams = new URLSearchParams(data)
  pixelCode.src = `https://stp.stack.la/record.png?event=${name}&${searchParams.toString()}`
  pixelCode.style.display = "none"
  const s = document.getElementsByTagName("body")[0]
  s.insertBefore(pixelCode, s.firstChild)
}

export function stacklaPixel(response: EventResponseMessage) {
  const stacklaData = response ? response.sp : undefined

  if (stacklaData) {
    stacklaData.e.forEach(event => {
      createImage(event.n, event.d)
    })
  }
}

export function initStacklaPixel() {
  bus.on("taggingsent", stacklaPixel)
}
