import text from "./text"

type Elements = HTMLElement[] | NodeListOf<HTMLElement>

export default function texts(nodes: Elements) {
  const t: string[] = []
  for (let i = 0; nodes && i < nodes.length; i += 1) {
    const data = text(nodes[i])
    if (data) {
      const trimmedText = data.trim()
      if (trimmedText.length > 0) {
        t.push(trimmedText)
      }
    }
  }
  return t
}
