import windows from "@/core/windows"
import context from "@/core/context"
import logger from "@/core/logger"

let bodyCopy: HTMLElement | null = null
/**
 * store the site's body at the point of first detection of placements
 * to facilitate the detection of dynamic placement targeting dynamic elements
 */
export function saveInitialBody() {
  if (!context.mode.isDebug() || bodyCopy) {
    return
  }
  try {
    bodyCopy = windows.site.document.body.cloneNode(true)
  } catch (e) {
    logger.error("failed to copy body", e)
  }
}

export function initialBody() {
  return bodyCopy
}
