import page from "@/core/page"
import { schemaFn, children, select } from "./utils/schema"
import parsePrice from "./utils/price"
import { CartItem } from "@/types"
import text from "./utils/text"
import pipe from "@/utils/pipe"
import defaultText from "./utils/defaultText"
import toInt from "./utils/toInt"
import { Cart } from "@/core/tagging/types"

const cartItemFn = schemaFn<CartItem>({
  product_id: select(".product_id"),
  sku_id: select(".sku_id"),
  name: select(".name"),
  quantity: select(".quantity", pipe(text, toInt)),
  unit_price: select(".unit_price", pipe(text, parsePrice)),
  price_currency_code: select(".price_currency_code")
})

const cartFn = schemaFn<Cart>({
  hcid: select(".nosto_cart > .hcid", pipe(text, defaultText("blank"))),
  items: children(".nosto_cart > .line_item, .nosto_cart > .items > .line_item", cartItemFn)
})

export default function findCart() {
  const cartDiv = page.select(".nosto_cart")
  if (!cartDiv) {
    return undefined
  }

  return cartFn(cartDiv)
}
