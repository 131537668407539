import { CategoryClick, CategoryImpression, SearchClick, SearchImpression } from "@/types"
import { findCustomer } from "@/core/tagging"
import logger from "@/core/logger"
import settings from "@/core/settings"
import getCustomerId from "@/core/customerId"
import { AnalyticsType, Endpoint } from "./types"
import visits from "@/core/visit"
import { send } from "@/core/analytics"

type Mapping = {
  "search:impression": SearchImpression
  "search:click": SearchClick
  "category:impression": CategoryImpression
  "category:click": CategoryClick
}

export async function reportAnalytics<T extends AnalyticsType, E extends Endpoint>(
  type: T,
  endpoint: E,
  data: Mapping[`${T}:${E}`]
) {
  const customerData = findCustomer()

  try {
    const customerId = await getCustomerId()
    if (!customerId) {
      if (!visits.isDoNotTrack()) {
        logger.warn("Skipping analytics event, no customer id defined")
      }
      return
    }
    const url = new URL(`${settings.server}/analytics/${type}/${endpoint}`)
    url.searchParams.append("merchant", settings.account)
    url.searchParams.append("c", customerId)
    if (customerData?.customer_reference) {
      url.searchParams.append("customerReference", customerData?.customer_reference)
    }
    await send(url.toString(), data, { delayed: endpoint === "click" })
  } catch (e) {
    logger.warn(`Failed to send ${type} ${endpoint} analytics`, e)
  }
}
