import microdata from "./microdata"
import jsonld from "./json-ld"
import { Product } from "@/core/tagging/types"

function toFloat(v: string | number) {
  return typeof v === "string" ? parseFloat(v) : v
}

function toInt(v: string | number) {
  return typeof v === "string" ? parseInt(v, 10) : v
}

/**
 * Sets a precision of 1 point to the float values. This is necessary to prevent
 * bad comparisons since the Java code uses a precision of 1 point as well.
 */
function normalizeValues(product: Product) {
  if (product.rating_value) {
    product.rating_value = toFloat(toFloat(product.rating_value).toFixed(1))
    product.rating_value = (Number.isNaN(product.rating_value) ? undefined : product.rating_value)!
  }
  if (product.review_count) {
    product.review_count = toInt(product.review_count)
    product.review_count = (Number.isNaN(product.review_count) ? undefined : product.review_count)!
  }
}

export default function parseAggregateRatings(product: Product) {
  if (!microdata(product)) {
    jsonld(product)
  }
  normalizeValues(product)
  return product
}
