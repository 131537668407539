import { Maybe } from "@/types"

/*
 * handles async placement appearance
 */
let raf: number

const readyPlacements: string[] = []

type SetPlacementsCallback = (elements: string[]) => void

let submitPlacementQueue: SetPlacementsCallback

function placementAppears(divId: string) {
  readyPlacements.push(divId)
  if (!raf) {
    raf = requestAnimationFrame(() => {
      if (submitPlacementQueue) {
        submitPlacementQueue(readyPlacements.slice())
      }
      readyPlacements.length = 0
      raf = 0
    })
  }
}

interface Observer {
  disconnect(): void
}

export class Queue<T, O extends Observer> {
  constructor(observerInit: () => O) {
    this.elements = new Map()
    this.observerInit = observerInit
    this.observer = null
  }
  elements: Map<string, T>
  observerInit: () => O
  observer: O | null

  queue(divId: string, data: T): Maybe<T> {
    if (!this.observer) {
      this.observer = this.observerInit()
    }
    const old = this.elements.get(divId)
    this.elements.set(divId, data)
    return old ?? undefined
  }

  /**
   * @param {function(T): V} check check that the queue element matches
   * @param {function(V,string,T): boolean } [skipSubmit] if placement is not considered ready yet
   * @template T,V
   */
  update<V>(check: (a: T) => V, skipSubmit?: (a: V, b: string, c: T) => boolean) {
    this.elements.forEach((data, divId) => {
      const checkValue = check(data)
      //
      if (checkValue) {
        this.elements.delete(divId)
        if (!skipSubmit || !skipSubmit(checkValue, divId, data)) {
          placementAppears(divId)
        }
      }
    })
    if (!this.elements.size) {
      this.observer?.disconnect()
      this.observer = null
    }
  }
}

/**
 * register "placements available" callback
 * @param {SetPlacementsCallback} fn
 */
export function setPlacementsCallback(fn: SetPlacementsCallback) {
  submitPlacementQueue = fn
}

export function asyncPlacementMode() {
  return !!submitPlacementQueue
}
