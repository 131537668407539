import windows from "./windows"

function select<E extends HTMLElement>(selector: string) {
  return windows.site.document.querySelector<E>(selector)
}

function selectAll<E extends HTMLElement>(selector: string) {
  return Array.from(windows.site.document.querySelectorAll<E>(selector))
}

export default {
  select,
  selectAll
}
