import mode from "@/core/mode"
import { parseCSS } from "./parser"
import { flatten, needsFlattening } from "./flatten"
import { stringify } from "./stringify"
import logger from "@/core/logger"

let cssNestingSupport: boolean | undefined

/**
 * Checks if CSS nesting is supported in the current browser environment.
 */
export function cssNestingSupported(force?: boolean) {
  if (cssNestingSupport === undefined || force) {
    try {
      // requires https://caniuse.com/mdn-api_cssstylesheet_cssstylesheet
      const sheet = new CSSStyleSheet()
      // requires https://caniuse.com/css-nesting
      sheet.insertRule("div { color: red; & > span { color: blue; }; .child { color: green; } }")
      cssNestingSupport = sheet.cssRules[0].cssRules.length === 2
    } catch (e) {
      cssNestingSupport = false
    }
  }
  return cssNestingSupport
}

/**
 * Transpiles nested CSS usage.
 * This is a polyfill for browsers that do not support CSS nesting.
 * It should be dropped from the client script once support is wider https://caniuse.com/css-nesting
 */
export function transpileNestedCSS(cssText: string) {
  try {
    const tokens = parseCSS(cssText)
    if (!tokens.some(needsFlattening)) {
      // only apply flattening if needed
      return undefined
    }
    const flattened = tokens.flatMap(flatten)
    return flattened.map(stringify).join("\n")
  } catch (e) {
    logger.info("Transpilation error", e)
    return undefined
  }
}

function getStyleElements(element: HTMLElement): HTMLStyleElement[] {
  if (element.matches && element.matches("style")) {
    return [element as HTMLStyleElement]
  } else if (typeof element.querySelectorAll === "function") {
    return Array.from(element.querySelectorAll<HTMLStyleElement>("style"))
  }
  return []
}

/**
 * Transpile the CSS contained in give elements.
 */
export function transpileCSS(...elements: HTMLElement[]) {
  if (cssNestingSupported() && !mode.isDebug()) {
    return // no need to transpile
  }
  elements.flatMap(getStyleElements).forEach(element => {
    // TEMP style[nested] is a temporary attribute to indicate nested css
    if (element.textContent && element.hasAttribute("nested")) {
      const transpiled = transpileNestedCSS(element.textContent)
      if (transpiled) {
        element.textContent = transpiled
        element.dataset.transpiled = "true"
      }
    }
  })
}
