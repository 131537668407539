import page from "@/core/page"
import { schemaFn, select } from "./utils/schema"
import { PushedCustomer } from "@/types"
import pipe from "@/utils/pipe"
import text from "./utils/text"
import defaultText from "./utils/defaultText"
import defaultValue from "./utils/defaultValue"
import settings from "@/core/settings"
import context from "@/core/context"

const newsletterFn = (s: string | undefined) => (s !== undefined ? s === "1" || s === "true" : undefined)

const customerFn = schemaFn<PushedCustomer>({
  hcid: select(".nosto_customer > .hcid", pipe(text, defaultText("blank"))),
  customer_reference: select(".nosto_customer > .customer_reference"),
  source: select(".nosto_customer > .source"),
  type: select(".nosto_customer > .type", pipe(text, defaultValue("loggedin"))),
  email: select(".nosto_customer > .email"),
  first_name: select(".nosto_customer > .first_name"),
  last_name: select(".nosto_customer > .last_name"),
  newsletter: select(".nosto_customer > .marketing_permission", pipe(text, newsletterFn))
})

function customerFromUrl() {
  if (settings.collectEmailFromURL && settings.emailAddressUrlParamName) {
    const email = context.siteUrl.searchParams.get(settings.emailAddressUrlParamName)
    if (email) {
      return { email, source: "url", type: "loggedin" }
    }
  }
}

function cleanup<T extends object>(o: T) {
  const cleaned = Object.fromEntries<T>(Object.entries(o).filter(e => e[1] !== "" && e[1] !== "undefined")) as T
  const fromUrl = customerFromUrl()
  return fromUrl ? { ...fromUrl, ...cleaned } : cleaned
}

export default function findCustomer(): PushedCustomer | undefined {
  const custDiv = page.select(".nosto_customer")
  if (!custDiv) {
    return customerFromUrl()
  }
  if (!page.select(".nosto_customer > *:not(:empty)")) {
    // require non empty children
    return customerFromUrl()
  }
  return cleanup(customerFn(custDiv))
}
