import { EventResponseMessage, FacebookData } from "@/types"
import { apiLoader } from "./facebook-api-loader"
import bus from "./api/bus"

let pixelInitialized = false

function loadPixel(facebookData: FacebookData) {
  const fbq = apiLoader()

  if (!pixelInitialized) {
    fbq("addPixelId", facebookData.p)
    fbq("trackSingle", facebookData.p, "PixelInitialized", { segments: facebookData.s })

    pixelInitialized = true
  }

  return fbq
}

export function firePixelEvents(data: EventResponseMessage) {
  const facebookData = data ? data.fb : undefined

  if (facebookData) {
    const fbq = loadPixel(facebookData)

    facebookData.e.forEach(event => {
      fbq("trackSingle", facebookData.p, event.n, event.d)
    })
  }
}

export function initFacebookPixel() {
  bus.on("taggingsent", firePixelEvents)
}
