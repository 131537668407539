import { Maybe } from "@/types"
import cookieBasedStore from "./cookie"
import { Store } from "./types"

let store = cookieBasedStore()
let doNotTrack = false

const visits: Visits = {
  getStore: () => store,
  setStore: (s: Store) => (store = s),
  isDoNotTrack: () => doNotTrack,
  setDoNotTrack: (dnt: boolean) => (doNotTrack = dnt),
  getCustomerId: () => store.getCustomerId(),
  setCustomerId: (id: string) => store.setCustomerId(id),
  // @deprecated
  setCustomerIdentifierService: (s: Store) => (store = s)
}
export default visits

// explicitly defined to avoid inlining in typedoc
export interface Visits {
  getCustomerId(): Maybe<string>
  getStore(): Store
  isDoNotTrack(): boolean
  setCustomerId(id: string | undefined): void
  setCustomerIdentifierService(s: Store): Store
  setDoNotTrack(dnt: boolean): boolean
  setStore(s: Store): Store
}
