import parseUri, { ParseUriResult } from "@/utils/parseuri"
import domReadyCreator from "domready"
import { cleanParsedUrl } from "@/utils/url"
import settings from "@/core/settings"
import windows from "./windows"
import { localStore } from "./store"
import mode, { setSiteUrl, Mode } from "./mode"
import { InitOptions, nostojs } from "./nostojs"

/**
 * @hidden
 */
export interface Context {
  namespace: string
  created: Date
  domHasLoaded: boolean
  loader: nostojs
  initOptions?: InitOptions
  /** @deprecated */
  updateSiteUrl: () => void
  siteUrl: ParseUriResult
  siteUrlCleaned: string
  referer?: ParseUriResult
  domReady: (fn: () => void) => void
  debugToken: string
  mode: Mode
  popupShown: boolean
}

// @ts-expect-error fix context building
const context: Context = setUrlProperties({})
export default context

/**
 * Sets siteUrl and siteUrlCleaned properties on the context object as getters
 */
function setUrlProperties(context: Context) {
  let siteUrl: ParseUriResult
  let siteUrlCleaned: string | undefined

  function updateUrls() {
    if (windows.site.location.href !== siteUrl?.href) {
      siteUrl = Object.freeze(parseUri(windows.site.location.href))
      siteUrlCleaned = cleanParsedUrl(siteUrl, settings.nostoRefParam)!
    }
  }

  Object.defineProperties(context, {
    siteUrl: {
      get() {
        updateUrls()
        return siteUrl
      }
    },
    siteUrlCleaned: {
      get() {
        updateUrls()
        return siteUrlCleaned
      }
    }
  })

  return context
}

/**
 * @param namespace used for testing
 */
export function createContext(): Context {
  if (Object.keys(context).length) {
    Object.keys(context).forEach(key => delete context[key])
  }
  context.namespace = "nosto"
  context.created = new Date()
  context.domHasLoaded = false

  if (windows.site.nostojs) {
    if (typeof windows.site.nostojs !== "function") {
      console.warn("window.nostojs already set as something other than a function")
    }
    context.loader = windows.site.nostojs
    context.initOptions = context.loader.o
  }
  if (!context.initOptions) {
    context.initOptions = {}
  }

  // kept for backwards compatibility
  context.updateSiteUrl = () => {}

  if (windows.site.document.referrer) {
    context.referer = parseUri(windows.site.document.referrer)
  }
  context.domReady = domReadyCreator(windows.site)

  setSiteUrl(context.siteUrl)
  context.mode = mode
  const debugVersion = context.siteUrl.searchParams.get("nostodebug") || localStore.get("nosto:dev")
  const hexShaRegEx = /^([a-f0-9]{40})$/
  if (debugVersion && hexShaRegEx.test(debugVersion)) {
    context.debugToken = debugVersion
  }

  windows.nosto._targetWindow = windows.site
  // @ts-expect-error unknown field
  windows.site[context.namespace] = windows.nosto
  return context
}
