import { pageTagging } from "@/core/tagging"
import trackOrder from "./track"
import bus from "@/core/api/bus"

export function initOrderHandling() {
  // Moved from taggingsent to ev1end to enfore processing of order tagging irrespective of the result of ev1 request
  bus.on("ev1end", processOrderTagging)
}

async function processOrderTagging() {
  const { order, pageType } = pageTagging()
  if (order) {
    await trackOrder(order)
  } else if (pageType === "order") {
    throw new Error("No order tagging found on order page.")
  }
}
