import { Level } from "./types"
import bus from "@/core/api/bus"
import { getError, getMessage, isLoggingFlag } from "./utils"
import settings from "@/core/settings"
import context from "@/core/context"

export default function scriptError(level: Level, args: unknown[]) {
  if ((level === "error" || level === "warn") && (settings.testing || context.mode.isDebug())) {
    const filtered = args.filter(a => !isLoggingFlag(a))
    const msg = getMessage(filtered)
    const { stack } = getError(filtered)
    bus.emit("scripterror", { msg, stack, level })
  }
}
