export function fromEntries<T>(entries: [string, T][]) {
  return Array.from(entries).reduce<Record<string, T>>((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

export function flatMap<T, U>(this: T[], callback: (value: T, index: number, array: T[]) => U[]) {
  return this.map(callback).flat()
}

export function flat<T>(this: T[], depth = 1) {
  return this.reduce<unknown[]>((acc, val) => {
    if (Array.isArray(val) && depth > 0) {
      acc.push(...val.flat(depth - 1))
    } else {
      acc.push(val)
    }
    return acc
  }, [])
}

export function hasOwn(obj: Record<string, unknown>, key: string) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

// baseline: ES2018
if (!Array.prototype.flat) {
  // @ts-expect-error signature mismatch
  Array.prototype.flat = flat
}
if (!Array.prototype.flatMap) {
  Array.prototype.flatMap = flatMap
}
if (!Object.fromEntries) {
  Object.fromEntries = fromEntries
}
// @ts-expect-error not yet officially supported
if (!Object.hasOwn) {
  // @ts-expect-error not yet officially supported
  Object.hasOwn = hasOwn
}
