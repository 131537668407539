import { AnalyticEventProperties, Maybe, maybe, SearchEventMetadata } from "@/types"
import { ABTest } from "../types"
import { SearchTrackOptions } from "./types"
import { resultIdCache } from "./cache"

type Endpoint = "impression" | "click"

const metadataDefaults = {
  query: "",
  resultId: uuidv4(),
  isOrganic: true,
  isAutoCorrect: false,
  isAutoComplete: false,
  isSorted: false,
  isKeyword: false,
  hasResults: true,
  isRefined: false,
  refinedQuery: ""
}

export function checkSearchMetadata(endpoint: Endpoint, metadata: SearchEventMetadata) {
  if (!metadata.resultId) {
    setResultId(endpoint, metadata)
  }
  return {
    ...metadataDefaults,
    ...metadata
  }
}

function uuidv4() {
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'number[]'... Remove this comment to see the full error message
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

function setResultId(endpoint: Endpoint, metadata: SearchEventMetadata) {
  const cache = resultIdCache.get()

  if (endpoint === "impression" || (endpoint === "click" && !cache)) {
    metadata.resultId = uuidv4()
  } else if (endpoint === "click") {
    metadata.resultId = cache!
  }

  if (endpoint === "impression") {
    resultIdCache.set(metadata.resultId)
  }
}

const autoCompleteState = {
  lastQuery: maybe<string>(),
  lastResultId: maybe<string>(),
  resultIdForEmptyQuery: uuidv4()
}

/**
 * Get a unique result id based on the query and the search type
 */
export function getResultId(type: SearchTrackOptions, query: string) {
  if (type === "autocomplete") {
    if (!query) {
      return autoCompleteState.resultIdForEmptyQuery
    }

    if (
      autoCompleteState.lastQuery &&
      (query.startsWith(autoCompleteState.lastQuery) || autoCompleteState.lastQuery.startsWith(query))
    ) {
      return autoCompleteState.lastResultId
    }

    autoCompleteState.lastQuery = query
    autoCompleteState.lastResultId = uuidv4()
    return autoCompleteState.lastResultId
  }

  return uuidv4()
}

export function getEventProperties(variations: ABTest[]): Maybe<AnalyticEventProperties> {
  const abTestAttribution = variations.reduce<Record<string, string>>((acc, test) => {
    acc[test.id] = test.activeVariation.id
    return acc
  }, {})
  return Object.keys(abTestAttribution).length ? { abTestAttribution } : undefined
}
