import toFloat from "./toFloat"

export default function parsePrice(text: string | undefined) {
  if (text) {
    const normalized = text
      .replace(/[^0-9.,-]/g, "")
      .replace(",", ".")
      .trim()
    return toFloat(normalized)
  }
  return undefined
}
