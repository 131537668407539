import Effects from "showhide"
import windows from "@/core/windows"
import page from "@/core/page"
import settings from "@/core/settings"
import measurePerformance from "@/core/measurePerformance"
import { toObjectKeys } from "@/utils/object"
import { injectStaticCampaign } from "./injection"
import subscribeIntersectionObserver from "./async/intersection"
import subscribeMutationObserver from "./async/mutation"
import { cssEscape } from "./selector"

const effects = new Effects()

const getPlacementsForInjection = () =>
  page.selectAll(".nosto_element:not(.nosto-dynamic-placement)").map(node => node.id)

const getPlacements = () => {
  const intersectionObservedDivIds = toObjectKeys(settings.intersectionObserved)
  const mutationObservedDivIds = toObjectKeys(settings.mutationObserved)
  // do not use `Array.from(array_like, function)` as there are not up-to-spec polyfills in the wild
  // that ignore the second parameter
  const res = page
    .selectAll(".nosto_element")
    .filter(node => {
      // placement is present on the page, no need to track with mutation observer
      delete mutationObservedDivIds[node.id]
      // skip the static placements that has `nosto_lazy` class and are not visible on the page currently
      // and be loaded lazily via `subscribeIntersectionObserver`
      return !subscribeIntersectionObserver(node, node.id, {
        intersection: node.classList.contains("nosto_lazy") || intersectionObservedDivIds[node.id]
      })
    })
    .map(node => node.id)
  Object.keys(mutationObservedDivIds).forEach(divId => {
    subscribeMutationObserver(divId, {
      mutation: true,
      cssSelector: cssEscape`#${divId}.nosto_element`,
      intersection: intersectionObservedDivIds[divId]
    })
  })

  return res
}

const injectCampaigns = (campaigns: Record<string, string>) => {
  return measurePerformance("nosto.inject_static_campaigns", () => {
    return Object.keys(campaigns)
      .map(key => {
        const value = campaigns[key]
        // noinspection TypeScriptValidateTypes
        const div = page.select(cssEscape`#${key}:not(.nosto-dynamic-placement)`)
        if (div) {
          injectStaticCampaign(div, value, key)
          effects.show(div, windows.site)
          const result: [string, HTMLElement[]] = [key, [div]]
          return result
        }
        return undefined
      })
      .filter(Boolean)
  })
}

const provider = {
  getPlacements,
  getPlacementsForInjection,
  injectCampaigns
}

export default provider
export type StaticProvider = typeof provider
