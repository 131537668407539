import context from "@/core/context"
import reportTimes from "@/core/timing"
import { EventResponseMessage } from "@/types"

/**
 * Reports times only from valid hosts (ie. don't report values from dev environments).
 * Only report only 1% of times but report on all debug requests.
 * @param response
 * @param date
 */
export default function (response: EventResponseMessage, date: Date) {
  const nostoReady = new Date()
  // noinspection JSUnresolvedVariable
  if (Math.random() < 0.01 || context.mode.isDebug()) {
    setTimeout(() => {
      reportTimes(nostoReady, date)
    }, 10000)
  }
}
