import innerHtml from "./innerHtml"

export default function text(node: HTMLElement): string
export default function text(node: HTMLElement | undefined): string | undefined

export default function text(node: HTMLElement | undefined) {
  if (node?.hasAttribute("html")) {
    return innerHtml(node)
  } else if (node) {
    const data = node.innerText || node.textContent || ""
    // Run a regexp to match IE 7 & 8 (and our crawler behavior)
    return data.replace(/\s+/g, " ").trim()
  }
  return undefined
}
