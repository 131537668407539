import context from "@/core/context"
import visit from "@/core/visit"
import settings from "../settings"
import { Level } from "./types"
import { getMessage, ErrorInstance, getError, isLoggingFlag } from "./utils"
import axios from "axios"

type ErrorPayload = {
  msg: string
  m: string
  c?: string
  siteUrl: string
  reporter: string
  level: Level
  scriptUrl?: string
  stack?: string
}

const previewMapping = {
  debug: "debug",
  log: "log",
  info: "debug",
  warn: "info",
  error: "warn"
} as const

function extractReporter({ reporter, stack }: ErrorInstance) {
  if (reporter) {
    return reporter
  }
  if (stack?.includes("search/templates")) {
    return "search:templates"
  }
  return "client"
}

function sender(payload: Record<string, string>, endpoint: string): Promise<unknown> {
  const endpointUrl = new URL(endpoint)
  Object.keys(payload).forEach(key => {
    endpointUrl.searchParams.append(key, payload[key])
  })
  return axios.get(endpointUrl.toString())
}

function generatePayload(level: Level, args: unknown[]) {
  const error = getError(args)
  const isPreview = context.mode.isPreview()
  const payload: ErrorPayload = {
    msg: getMessage(args),
    m: settings.account,
    c: visit.getCustomerId(),
    siteUrl: window.location.href,
    reporter: extractReporter(error),
    level
  }

  if (level) {
    payload.level = (isPreview && previewMapping[level]) || level
  }
  if (error.fileName) {
    payload.scriptUrl = error.fileName
  }
  if (error.lineNumber) {
    payload.scriptUrl += `:${error.lineNumber}`
  }
  if (error.columnNumber) {
    payload.scriptUrl += `:${error.columnNumber}`
  }
  if (error.name) {
    payload.msg += ` name: ${error.name}`
  }
  if (error.stack) {
    payload.stack = error.stack
  }

  return payload
}

export default function jserror(level: Level, args: unknown[]) {
  const flag = args.find(isLoggingFlag)
  if (flag?.local || (flag?.sampled && Math.random() > 0.1)) {
    return Promise.resolve()
  }
  if ((level !== "error" && level !== "warn") || !settings.jsErrorUrl) {
    return Promise.resolve()
  }
  const payload = generatePayload(level, args)
  return sender(payload, settings.jsErrorUrl)
}
