import { Appender, Level } from "./types"

const appenders: Appender[] = []

export function addAppenders(...args: Appender[]) {
  appenders.push(...args)
}

function log(level: Level, args: unknown[]) {
  try {
    appenders.forEach(appender => appender(level, args))
  } catch (e) {
    // direct console.error to avoid infinite loop
    console.error("Error in logging", e)
  }
}

export default {
  log: (...args: unknown[]) => log("log", args),
  warn: (...args: unknown[]) => log("warn", args),
  info: (...args: unknown[]) => log("info", args),
  error: (...args: unknown[]) => log("error", args),
  debug: (...args: unknown[]) => log("debug", args)
}
