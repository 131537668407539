import { deepClone } from "@/utils/deepClone"
import bus from "@/core/api/bus"
import logger from "@/core/logger"
import { pageTagging } from "@/core/tagging"
import createRequestBuilder from "./request"

/**
 * Resends the cart tagging to Nosto. Only the cart information is sent over
 * and nothing else - no placements, no events, nothing.
 * This method was originally used by the Magento 2 plugin. In the Magento 2 plugin
 * the cart tagging is loaded asynchronously so a method like this was needed.
 * @return {Promise}
 */
export async function resendCartTagging() {
  const tagging = deepClone(pageTagging())
  if (tagging && tagging.cart) {
    await createRequestBuilder()
      .setCartContent({
        hcid: tagging.cart.hcid,
        items: tagging.cart.items
      })
      .setRestoreLink(tagging.restoreLink)
      .send({ skipPageViews: true })
    bus.emit("carttaggingresent", {
      cart_items: tagging.cart.items,
      restore_link: tagging.restoreLink
    })
    return
  }
  logger.info("No cart tagging found.")
}

/**
 * Resends the customer tagging to Nosto. Only the c  ustomer information is sent over
 * and nothing else - no placements, no events, nothing.
 * This method was originally used by the Magento 2 plugin. In the Magento 2 plugin
 * the customer tagging is loaded asynchronously so a method like this was needed.
 *
 * @return {Promise}
 */
export async function resendCustomerTagging() {
  const tagging = deepClone(pageTagging())
  if (tagging && tagging.customer) {
    await createRequestBuilder().setCustomer(tagging.customer).send({ skipPageViews: true })
    bus.emit("customertaggingresent", tagging.customer)
    return
  }
  logger.info("No customer tagging found.")
}
