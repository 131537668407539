import emptyToUndefined from "./empty"
import texts from "./texts"

type Elements = HTMLElement[] | NodeListOf<HTMLElement>

export default function keyValueTexts(nodes: Elements) {
  const fields: Record<string, string[]> = {}
  const contents = texts(nodes)
  contents.forEach(item => {
    const splitItems = item.split(":")
    if (fields.hasOwnProperty(splitItems[0])) {
      fields[splitItems[0]].push(splitItems[1])
    } else {
      fields[splitItems[0]] = [splitItems[1]]
    }
  })
  return emptyToUndefined(fields)
}
