import settings from "./settings"
import windows from "./windows"

export function isLoaded() {
  return windows.site.document.querySelector("script#nwc-script") !== null
}

export async function load() {
  if (isLoaded()) {
    return
  }
  const { document } = windows.site
  const url = `${settings.server}/assets/public/jsbuild/web-components/main.es.js?z=!CACHEVALUE!`
  const scriptElement = document.createElement("script")
  scriptElement.src = url
  scriptElement.setAttribute("id", "nwc-script")
  scriptElement.type = "module"
  document.head.prepend(scriptElement)
}

export async function initWebComponents() {
  if (settings.webComponents) {
    await load()
  }
}
