import bus from "@/core/api/bus"
import logger from "@/core/logger"
import createRequestBuilder from "./request"
import { Experiment } from "@/types"

export async function setExperiments(experiments: Experiment[]) {
  if (experiments) {
    await createRequestBuilder().setExperiments(experiments).send({ skipPageViews: true })
    bus.emit("setexperiments", { experiments })
    return
  }
  logger.info("No experiment info found.")
}
