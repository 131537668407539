import axios from "axios"
import settings from "@/core/settings"
import context from "@/core/context"
import { findCustomer } from "@/core/tagging"
import windows from "@/core/windows"
import { deepClone } from "@/utils/deepClone"
import { validateEvent } from "./validator"
import { Event, EventTuple, toEventTuple, isEventType, EventType } from "@/core/event"
import visit from "@/core/visit"

type AttributionTuple = [type: EventType, target?: string, ref?: string, refSrc?: string]

function isEventTupleFormat(args: unknown[]): args is AttributionTuple {
  return typeof args[0] === "string" && isEventType(args[0]) && args.length <= 4
}

function getEventObject(args: IArguments) {
  if (isEventTupleFormat([...args])) {
    const [type, target, ref, refSrc] = args
    return { type, target, ref, refSrc }
  }
}

function createAttribution(): Attribution {
  const _events: EventTuple[] = []

  return {
    recordAttribution(event: Event) {
      // eslint-disable-next-line prefer-rest-params
      const eventObject = getEventObject(arguments) ?? event
      if (validateEvent(eventObject)) {
        _events.push(toEventTuple(eventObject))
      }
      return this
    },

    dumpData() {
      return deepClone(_events)
    },

    done() {
      if (_events.length === 0) {
        return Promise.reject({
          response: {
            status: 400,
            data: {
              errors: ["No events supplied for recording attribution"]
            }
          }
        })
      }

      const customerData = findCustomer()
      const data = {
        events: _events,
        metadata: {
          referrer: windows.site.document.referrer,
          url: context.siteUrl.href,
          date: new Date(),
          customer_reference: customerData ? customerData.customer_reference : undefined
        }
      }

      return axios.post(`${settings.server}/attribution/record`, data, {
        params: {
          merchant: settings.account,
          c: visit.getCustomerId()
        },
        headers: {
          "Content-Type": "text/plain"
        }
      })
    }
  }
}

export interface Attribution {
  recordAttribution: (event: Event) => Attribution
  dumpData: () => EventTuple[]
  done: () => Promise<void>
}

export default createAttribution
