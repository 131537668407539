import { ParseUriResult } from "@/utils/parseuri"
import { localStore } from "./store"
import { DebugRequestParamsDTO } from "@/types"
import { isbot } from "isbot"

type Storage = {
  "nosto:preview": boolean
  "nosto:recotrace": boolean
  "nosto:skipcache": boolean
  "nosto:dev": string | boolean
  "nosto:debug": DebugRequestParamsDTO
}

function clear() {
  localStore.remove("nosto:preview")
  localStore.remove("nosto:recotrace")
  localStore.remove("nosto:skipcache")
  localStore.remove("nosto:dev")
  localStore.remove("nosto:debug")
}

function setStorageValue<K extends keyof Storage>(key: K, value: Storage[K] | undefined) {
  if (value) {
    localStore.set(key, JSON.stringify(value))
  } else {
    localStore.remove(key)
  }
}

const hexShaRegEx = /^([a-f0-9]{40})$/

export function setSiteUrl(url: ParseUriResult) {
  // Set the preview mode based on the query parameter
  const preview = url.searchParams.get("nostodebug") || localStore.get("nosto:dev")
  if (preview && (preview === "v3" || preview === "true" || hexShaRegEx.test(preview))) {
    localStore.set("nosto:dev", preview)
  } else {
    clear()
  }
}

function isDebug() {
  const preview = localStore.get("nosto:dev")
  return !!preview && preview !== "false"
}

function isPreview() {
  return localStore.get("nosto:preview") === "true"
}

function setPreview(optArg: boolean) {
  setStorageValue("nosto:preview", optArg)
}

function setRecotrace(optArg: boolean) {
  setStorageValue("nosto:recotrace", optArg)
}

function setSkipCache(optArg: boolean) {
  setStorageValue("nosto:skipcache", optArg)
}

function setDev(optArg: boolean) {
  setStorageValue("nosto:dev", optArg)
}

function setDebugState(optArg: DebugRequestParamsDTO | undefined) {
  setStorageValue("nosto:debug", optArg)
}

function isRecotraceEnabled() {
  return localStore.get("nosto:recotrace") === "true"
}

function skipCache() {
  return localStore.get("nosto:skipcache") === "true"
}

function getDebugState() {
  return localStore.getAsJson<DebugRequestParamsDTO>("nosto:debug")
}

function isBot() {
  return isbot(navigator.userAgent)
}

const mode = {
  isPreview,
  setPreview,
  setRecotrace,
  setSkipCache,
  setDev,
  setDebugState,
  clear,
  isDebug,
  isRecotraceEnabled,
  skipCache,
  getDebugState,
  isBot
}

export default mode
export type Mode = typeof mode
