/* Internal helper functions */
import type { PopupTrigger, ReqData } from "../types"

export function isAbandonedCartPopup(trigger: PopupTrigger) {
  return trigger === "abandonedCart"
}

function removeEmpty(params: ReqData) {
  return Object.keys(params).reduce<Record<string, string>>((result, key) => {
    const val = params[key]

    if (val !== undefined && val !== null && val !== "") {
      result[key] = String(val)
    }

    return result
  }, {})
}

export const reqDataParams = (rq: ReqData) => new URLSearchParams(removeEmpty(rq)).toString()

export function validateEmail(email: string): boolean {
  if (!email || typeof email !== "string") return false
  // Check if email is too long (most servers limit to 254 characters)
  if (email.length > 254) return false
  // More comprehensive regex for email validation
  // Checks for valid local part, @ symbol, and valid domain with at least one dot
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

  return re.test(email)
}
