import windows from "@/core/windows"

/**
 * Copy-pasted from app/views/visit/facebook.js
 * @returns {Object | Function}
 */
export const apiLoader = () => {
  if (!windows.site.fbq) {
    loadAPI()
  }
  return windows.site.fbq
}

function loadAPI() {
  const { document } = windows.site
  const fbds = document.createElement("script")
  fbds.async = true
  // Text taken from https://developers.facebook.com/docs/facebook-pixel/implementation/
  fbds.text =
    "!function(f,b,e,v,n,t,s)\n" +
    "  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
    "  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
    "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
    "  n.queue=[];t=b.createElement(e);t.async=!0;\n" +
    "  t.src=v;s=b.getElementsByTagName(e)[0];\n" +
    "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
    "  'https://connect.facebook.net/en_US/fbevents.js'); "
  const s = document.getElementsByTagName("body")[0]
  s.insertBefore(fbds, s.firstChild)
}
