import logger from "@/core/logger"
import settings from "@/core/settings"
import { PerLinkAttributions, wrapWithAttribution } from "@/core/parameterlessAttribution"
import { initialBody, saveInitialBody } from "@/placements/initialBody"
import { removeInjectedCampaign, resetElements } from "@/placements/injection"
import measurePerformance from "@/core/measurePerformance"
import type { StaticProvider } from "@/placements/static"
import type { DynamicProvider } from "@/placements/dynamic"

export interface PlacementCampaign {
  html: string
  result_id: string
  extra_attribution?: PerLinkAttributions
}

function getMarkup(campaign: string | PlacementCampaign) {
  return (typeof campaign === "string" ? campaign : campaign?.html) ?? ""
}

export default function createJoiningProvider(staticProvider: StaticProvider, dynamicProvider: DynamicProvider) {
  const getPlacements = () => {
    saveInitialBody()
    return [...staticProvider.getPlacements(), ...dynamicProvider.getPlacements()]
  }

  const injectCampaigns = (campaigns: Record<string, string | PlacementCampaign>) => {
    return measurePerformance("nosto.inject_campaigns", () => {
      // support for JSON response mode where the content campaigns are wrapped in an object
      const injectableCampaigns: Record<string, string> = {}
      Object.keys(campaigns || {}).forEach(key => {
        injectableCampaigns[key] = getMarkup(campaigns[key])
      })
      const staticPlacements = staticProvider.getPlacementsForInjection()
      const dynamicInjected: [string, HTMLElement[]][] = dynamicProvider.injectCampaigns(injectableCampaigns)
      dynamicInjected.forEach(([divId]) => {
        if (staticPlacements.includes(divId)) {
          logger.warn(
            `The placement ${divId} is defined as both as static and dynamic placement. In these situations, the dynamic placement takes precedence.`,
            { local: true }
          )
        }
        delete injectableCampaigns[divId]
      })

      const staticInjected: [string, HTMLElement[]][] = staticProvider.injectCampaigns(injectableCampaigns)
      staticInjected.forEach(res => delete injectableCampaigns[res[0]])

      const injection = [...staticInjected, ...dynamicInjected]
      if (settings.parameterlessAttribution) {
        injection.forEach(([divId, elements]) => {
          elements.forEach(e =>
            wrapWithAttribution(
              e,
              {
                // @ts-expect-error campaign is not a string
                ref: campaigns[divId].result_id
              },
              // @ts-expect-error campaign is not a string
              campaigns[divId].extra_attribution
            )
          )
        })
      }
      return {
        filledElements: injection.map(e => e[0]),
        unFilledElements: Object.keys(injectableCampaigns)
      }
    })
  }

  /**
   * Allows to reset the static and dynamic placement's altered content.
   * For dynamic placement, resetting is achieved technically by replacing
   * it with original elements, stored at the moment of campaign injection
   * For static placements, their content will be simply cleared
   * Use cases: debug toolbar preview toggle
   */
  const reset = resetElements

  /**
   * Checks if the placement conforms it's url and page type filters
   * (only applies for dynamic placements)
   * Use cases: debug toolbar
   * @param {Object}placementConfig
   * @returns {Boolean}
   */
  const isFiltered = dynamicProvider.isFiltered

  /**
   * Checks if there are filters configured for placement
   * Use cases: debug toolbar
   * @param {Object}placementConfig
   * @returns {Boolean}
   */
  const isFilteringConfigured = dynamicProvider.isFilteringConfigured

  const removeContent = removeInjectedCampaign

  return {
    getPlacements,
    injectCampaigns,
    reset,
    isFiltered,
    isFilteringConfigured,
    removeContent,
    initialBody
  }
}
