import { Axios, AxiosRequestConfig } from "axios"

/**
 * This check should return true if the request should be serialized.
 */
type SerializeCheck = (config: AxiosRequestConfig) => unknown

export default function pipeline(axios: Axios, check: SerializeCheck, parallelCount: number = 1) {
  let pendingRequests = 0

  axios.interceptors.request.use(config => {
    if (!check(config)) {
      return Promise.resolve(config)
    } else {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (pendingRequests < parallelCount) {
            pendingRequests += 1
            clearInterval(interval)
            resolve(config)
          }
        }, 10)
      })
    }
  })

  axios.interceptors.response.use(
    response => {
      pendingRequests = Math.max(0, pendingRequests - 1)
      return Promise.resolve(response)
    },
    error => {
      pendingRequests = Math.max(0, pendingRequests - 1)
      return Promise.reject(error)
    }
  )
}
