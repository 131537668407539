import { putAttribution } from "@/core/parameterlessAttribution"
import logger from "@/core/logger"
import { getVariations } from "../variations"
import { checkSearchMetadata, getEventProperties } from "./helpers"
import { reportAnalytics } from "./api"
import { getMetadataCache } from "./cache"
import bus from "@/core/api/bus"
import { assertValidClick, assertValidType } from "./validation"
import { SearchHit, SearchTrackOptions } from "./types"
import createAttribution from "@/core/api/event/attribution"
import { VIEW_PRODUCT } from "@/core/event"

/**
 * Record search click event
 */
export async function recordSearchClick(type: SearchTrackOptions, hit: SearchHit) {
  if (type === "autocomplete" && "keyword" in hit) {
    logger.info("Skipping search click event for autocomplete with keyword", hit.keyword)
    return
  }
  assertValidType(type)
  assertValidClick(hit)

  // prepare parameterless attribution metadata
  const resultId = await recordAttribution(type, hit, "click")
  if (hit.url && resultId) {
    try {
      putAttribution(hit.url, { ref: resultId })
    } catch (e) {
      // no need to block the click event if attribution fails
      logger.error("Could not store attribution for click", e)
    }
  }
}

export async function recordSearchAddToCart(type: SearchTrackOptions, hit: SearchHit) {
  if (type === "autocomplete" && "keyword" in hit) {
    logger.info("Skipping search click event for autocomplete with keyword", hit.keyword)
    return
  }
  assertValidType(type)
  assertValidClick(hit)
  const resultId = await recordAttribution(type, hit, "addtocart")

  if (resultId) {
    // create VP event directly
    await createAttribution().recordAttribution({ type: VIEW_PRODUCT, target: hit.productId, ref: resultId }).done()
  }
}

async function recordAttribution(type: SearchTrackOptions, hit: SearchHit, mode: "click" | "addtocart") {
  if (type === "category") {
    const metadata = getMetadataCache(type).get()
    if (metadata?.category || metadata?.categoryId) {
      const properties = getEventProperties(getVariations())
      const data = { productId: hit.productId, metadata, properties }
      bus.emit(`category${mode}`, data)
      await reportAnalytics("category", "click", data)
    }
  } else {
    const metadata = getMetadataCache(type).get()
    if (metadata) {
      const properties = getEventProperties(getVariations())
      metadata.isAutoComplete = type === "autocomplete"
      const data = { productId: hit.productId, metadata, properties }
      bus.emit(`search${mode}`, data)
      const normalized = { ...data, metadata: checkSearchMetadata("click", data.metadata) }
      await reportAnalytics("search", "click", normalized)
      return metadata.resultId
    }
  }
}
