import context from "@/core/context"
import page from "@/core/page"
export { default as findProducts } from "@/metadata/product/product"
export { default as findCustomer } from "@/metadata/customer"
export { default as findCart } from "@/metadata/cart"
export { default as findOrder } from "@/metadata/order"
export { default as findPluginVersions } from "@/metadata/plugin"
import emptyToUndefined from "@/metadata/utils/empty"
import texts from "@/metadata/utils/texts"
import keyValueTexts from "@/metadata/utils/keyvalue"
import { Maybe, PageType, pageTypeAliases } from "@/types"
import settings from "@/core/settings"

const aliasMapping = Object.entries(pageTypeAliases).reduce<Record<string, string>>((acc, [key, values]) => {
  values.forEach(value => (acc[value] = key))
  return acc
}, {})

export function findCurrentVariation(): Maybe<string> {
  const value = texts(page.selectAll(".nosto_variation"))[0]
  return value && value.trim() ? value : findCurrentPriceVariation()
}

export function findCurrentPriceVariation(): Maybe<string> {
  return texts(page.selectAll(".nosto_price_variation"))[0]
}

export function findSearchTerms(): Maybe<string[]> {
  if (settings.searchQueryParam && context.siteUrl.searchParams.has(settings.searchQueryParam)) {
    return emptyToUndefined([context.siteUrl.searchParams.get(settings.searchQueryParam)].filter(Boolean))
  }
  const terms = texts(page.selectAll(".nosto_search_term"))
  return emptyToUndefined(terms)
}

export function findCurrentCategories(): Maybe<string[]> {
  let cats = texts(page.selectAll(".nosto_category > .category_string"))
  if (!cats.length) {
    cats = texts(page.selectAll(".nosto_category"))
  }
  return emptyToUndefined(cats)
}

export function findCurrentCategoryIds(): Maybe<string[]> {
  const ids = texts(page.selectAll(".nosto_category > .id"))
  return emptyToUndefined(ids)
}

export function findCurrentParentCategoryIds(): Maybe<string[]> {
  const ids = texts(page.selectAll(".nosto_category > .parent_id"))
  return emptyToUndefined(ids)
}

export function findCurrentTags(): Maybe<string[]> {
  const tags = texts(page.selectAll(".nosto_tag"))
  return emptyToUndefined(tags)
}

export function findCurrentCustomFields(): Maybe<Record<string, string[]>> {
  const fields = keyValueTexts(page.selectAll(".nosto_custom_field"))
  const hasValue = fields && Object.keys(fields).length > 0
  return hasValue ? fields : undefined
}

export function findRestoreLink(): Maybe<string> {
  return texts(page.selectAll(".restore_link"))[0]
}

export function findAffinitySignals(): Maybe<Record<string, string[]>> {
  const fields = keyValueTexts(page.selectAll(".nosto_affinity_signals"))
  const hasValue = fields && Object.keys(fields).length > 0
  return hasValue ? fields : undefined
}

export function findPageType(): Maybe<PageType> {
  const values = texts(page.selectAll(".nosto_page_type"))
  if (values.length) {
    const normalized = values[0].toLowerCase()
    return (aliasMapping[normalized] || normalized) as PageType
  }
}

export function findSortOrder(): Maybe<string> {
  const values = texts(page.selectAll(".nosto_sort_order"))
  return values.length ? values[0].toLowerCase() : undefined
}

export function findElements(ignoredPlacements: string[]): Maybe<string[]> {
  const ids: string[] = []
  const nodes = page.selectAll(".nosto_element")
  for (let i = 0; i < nodes.length; i += 1) {
    if (!ignoredPlacements || ignoredPlacements.indexOf(nodes[i].id) === -1) {
      ids.push(nodes[i].id)
    }
  }
  return ids
}

export function findForcedSegments(): Maybe<string[]> {
  const nostosegments = context.siteUrl.searchParams.get("nostosegments")
  // url parameter takes precedence over segments in cookie
  if (nostosegments) {
    let fromUrl = nostosegments.split("~")
    if (fromUrl && fromUrl.length === 1) {
      fromUrl = nostosegments.split("%7E")
    }
    return fromUrl
  }
  return undefined
}
