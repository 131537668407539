// TODO merge this file with the equivalent file in nosto-loader
import settings from "@/core/settings"
import windows from "@/core/windows"
import context from "@/core/context"
import logger from "@/core/logger"
import { searchOrCategoriesEnabled } from "./utils"

type NostoTemplatesFileResolver = (path: string) => string

function loadSearch(window: Window, document: Document, nostoTemplatesFileResolver: NostoTemplatesFileResolver) {
  if (window.nostoTemplatesLoaded) {
    return
  }
  window.nostoTemplatesLoaded = true

  const script = document.createElement("script")
  script.type = "text/javascript"
  script.src = nostoTemplatesFileResolver("bundle.js")
  script.async = true
  document.body.appendChild(script)

  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.type = "text/css"
  link.media = "all"
  link.href = nostoTemplatesFileResolver("bundle.css")
  document.head.appendChild(link)
}

function loadScript(nostoTemplatesFileResolver: NostoTemplatesFileResolver) {
  const window = windows.site
  window.nostoTemplatesConfig = {
    merchant: settings.account,
    defaultCurrency: settings.defaultCurrencyCode
  }
  const { document } = window
  loadSearch(window, document, nostoTemplatesFileResolver)
}

function loadProduction() {
  if (!settings.searchDeploymentId) {
    logger.info("No search template is deployed")
    return
  }
  const base = `${settings.searchTemplateHost}/deployment/${settings.searchDeploymentId}/`
  loadScript(v => new URL(v, base).toString())
}

function loadPreview() {
  const ts = +Date.now()
  const base = `${settings.searchTemplateHost}/source/build/`
  loadScript(path => new URL(`${path}?ts=${ts}`, base).toString())
}

export default function load() {
  if (!searchOrCategoriesEnabled() || !settings.searchTemplatesEnabled) {
    return
  }
  const loadFn = context.mode.isPreview() ? loadPreview : loadProduction
  loadFn()
}
