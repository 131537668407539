import "@/polyfills"
import axios, { AxiosRequestConfig } from "axios"
import { initOrderHandling } from "@/order/tagging"
import { initOverlay, activateOverlay } from "@/overlay"
import { initToolbar } from "@/core/toolbar"
import { initWebComponents } from "@/core/web-components"
import { hideDynamicPlacements } from "@/placements/placementHiding"
import jserrorAppender from "@/core/logger/jserror"
import { createContext, Context } from "@/core/context"
import { watchAddToCartEvent } from "@/core/cartPopupTrigger"
import { initCmp } from "@/cmp/plugin"
import processQueuedAPICalls from "@/core/nostojs"
import settings, { Settings, modifySettings } from "@/core/settings"
import { initProductHandling } from "@/product/push"
import validateHost from "@/core/validate-host-on-load"
import windows from "@/core/windows"
import api, { API } from "@/core/api"
import pipelineOne from "@/utils/axios/pipeline"
import addCustomer from "@/utils/axios/customer"
import addOptout from "@/utils/axios/optout"
import loadSearch from "@/search/load"
import { initSearch } from "@/search/search"
import { initAnalytics } from "./analytics"
import { readAttribution } from "@/core/parameterlessAttribution"
import loadStacklaTracking from "@/stackla/loadStacklaTracking"
import logger, { addAppenders } from "./logger"
import consoleAppender from "./logger/console"
import scripterrorAppender from "./logger/scriptError"
import { CookieError, DomainError } from "@/errors"
import { initUnsubscribePanel } from "./unsubscriber"
import { initGoogleAnalytics } from "./google-analytics"
import { initStacklaPixel } from "@/stackla/stacklaPixel"
import { initFacebookPixel } from "./facebook"
import { isPromise } from "@/utils/isPromise"
import { waitFor } from "./waitFor"

function nostoLoaded(window: Window) {
  if (typeof window.nosto !== "undefined") {
    if (typeof window.nosto !== "object") {
      console.warn("window.nosto already set as something other than an object")
    }
    return true
  }

  // check if nosto is in parent
  //
  // if the store is in an iframe already and nosto is included directly
  // (without the embed) then referring window.parent can throw security
  // exception for cross-origin reference
  try {
    return window.parent && typeof window.parent.nosto !== "undefined"
  } catch (e) {
    /* ignore the possible security exception, then nosto is not loaded */
  }
  return false
}

async function getNostoContext() {
  const context = createContext()
  if (context.mode.isDebug()) {
    // in debug mode we will do an additional call for updated settings to avoid stale views
    const response = await axios.get<Settings>(`${settings.server}/include/${settings.account}/settings.json`)
    modifySettings(response.data)
    return createContext()
  }
  return context
}

async function apiLoad(force?: boolean) {
  if (force || api.isAutoLoad()) {
    activateOverlay()
    await api.load()
  }
}

export function serializeCheck({ url, method }: AxiosRequestConfig) {
  if (url?.startsWith(settings.server)) {
    return method === "post" || url.includes("/ev1") || url.includes("/cmp-mapping/")
  }
  return false
}

function initAxios() {
  axios.defaults.timeout = 5000
  addCustomer(axios)
  addOptout(axios)
  pipelineOne(axios, serializeCheck)
}

function initApi(context: Context) {
  if (context.initOptions?.disableAutoLoad) {
    api.setAutoLoad(false)
  }
  if (context.initOptions?.disableRecommendations) {
    api.setRecommendationsEnabled(false)
  }
}

async function invoke(fn: (api: API) => unknown) {
  try {
    const result = fn(api)
    if (isPromise(result)) {
      await result
    }
  } catch (err) {
    logger.warn("Error in init function", err)
  }
}

function loadNosto(context: Context) {
  validateHost()
  initApi(context)

  const inits = [
    initAxios,
    initAnalytics,
    loadSearch,
    loadStacklaTracking,
    initToolbar,
    initWebComponents,
    initOverlay,
    processQueuedAPICalls,
    initCmp,
    hideDynamicPlacements,
    watchAddToCartEvent,
    initOrderHandling,
    initProductHandling,
    initSearch,
    initUnsubscribePanel,
    initGoogleAnalytics,
    initStacklaPixel,
    initFacebookPixel
  ]
  inits.forEach(invoke)

  // TODO remove this dom-ready check if not really needed.
  context.domReady(() => {
    context.domHasLoaded = true
    void apiLoad()
  })
}

export async function init(win: Window) {
  win = win || window
  if (nostoLoaded(win)) {
    // Nosto already loaded
    return
  }
  // @ts-expect-error TS(2304) FIXME: Cannot find name '_sandboxed'.
  if (typeof _sandboxed === "undefined") {
    // wait until body has loaded
    if (!win.document.body) {
      setTimeout(() => init(win), 10)
      return
    }
  }
  readAttribution()

  addAppenders(consoleAppender, jserrorAppender, scripterrorAppender)

  try {
    const context = await getNostoContext()
    if (windows.site.nostoab && windows.site.nostoab.settings) {
      modifySettings(windows.site.nostoab.settings)
    }

    // This allows Nosto to be reinitialized and is primarily used in tests
    windows.nosto.reload = (options: Partial<Settings>) => {
      modifySettings(options)
      const context = createContext()
      loadNosto(context)
    }

    windows.nosto.waitFor = waitFor

    loadNosto(context)
  } catch (err) {
    const warnErrors = [CookieError, DomainError]
    const level = warnErrors.some(errorType => err instanceof errorType) ? "warn" : "error"
    logger[level]("Error initializing Nosto", err)
  }
}
