import axios from "axios"
import visit from "@/core/visit"
import settings from "./settings"
import { firePixelEvents } from "./facebook"
import { sendSegments } from "./google-analytics"
import { EventResponseMessage } from "@/types"

export default async function processBrowserQueue() {
  if (!settings.browserQueueActive) {
    return
  }

  const response = await axios.get<EventResponseMessage>(`${settings.server}/event/queue`, {
    params: {
      m: settings.account,
      c: visit.getCustomerId()
    }
  })
  if (response.status === 200) {
    firePixelEvents(response.data)
    sendSegments(response.data)
  }
}
