import bus from "@/core/api/bus"
import logger from "@/core/logger"
import ev1state from "@/core/ev1/state"
import createRequestBuilder from "./request"

export async function addSegment(segment: string): Promise<void> {
  if (segment) {
    await createRequestBuilder().setSegmentCodes([segment]).send({ skipPageViews: true })
    bus.emit("setsegments", { segment })
    return
  }
  logger.info("No segment code given.")
}

export const getSegments = ev1state("segments")
