import { Level } from "./types"
import context from "@/core/context"
import { isLoggingFlag } from "./utils"

export default function consoleLog(level: Level, args: unknown[]) {
  if ((context.mode.isDebug() || level === "error") && window.console && window.console[level]) {
    const filtered = args.filter(a => !isLoggingFlag(a))
    Function.apply.call(window.console[level], window.console, filtered)
  }
}
