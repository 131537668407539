/**
 * File handles the dom manipulations from placements and tracking the injected elements
 */
import windows from "@/core/windows"
import page from "@/core/page"
import createLoader from "@/core/loaders"
import createSelector, { cssEscape } from "./selector"
import { DynamicPlacementDTO, InsertMode, WrapMode } from "@/types"

const loaders = createLoader(() => windows.nosto)
const selector = createSelector(windows.site.document, loaders)

type StateMode = InsertMode | "HTML"

type InjectState = Readonly<{
  mode: StateMode
  original?: readonly HTMLElement[]
  replacement?: readonly HTMLElement[]
  wrapper?: WrapMode
}>

const manipulationState: Record<string, InjectState> = {}

const STATIC: InjectState = Object.freeze({
  mode: "HTML"
})

export function injectStaticCampaign(div: HTMLElement, value: string, key: string) {
  void selector.html(div, value)
  manipulationState[key] = STATIC
}

function performCleanup(divId: string, remove: boolean) {
  const state = manipulationState[divId]
  if (state) {
    if (state.replacement) {
      state.replacement.forEach(e => {
        e.remove()
      })
    } else {
      const elements = page.selectAll(cssEscape`#${divId}.nosto_element`)
      if (elements.length) {
        if (state.mode === "HTML") {
          elements[0].innerHTML = ""
        } else {
          elements.forEach(element => {
            element.replaceWith(...(remove ? [] : state.original!))
          })
        }
      }
    }
    delete manipulationState[divId]
  } else {
    const lostDynamicPlacementCampaign = page.selectAll(cssEscape`#${divId}.nosto_element.nosto-dynamic-placement`)
    lostDynamicPlacementCampaign.forEach(el => {
      el.remove()
    })
  }
}

export function removeInjectedCampaign(divId: string) {
  performCleanup(divId, true)
}

export function resetElement(divId: string) {
  performCleanup(divId, false)
}

export function resetElements() {
  Object.keys(manipulationState).forEach(resetElement)
}

function validateWrapper(mode: InsertMode, wrapper: WrapMode | "COMPLETE") {
  // disallow replacing with unwrapped content
  return (mode === "REPLACE" && wrapper === "UNWRAPPED") ||
    // disallow reuse of target for insert manipulation
    ((mode === "INSERT_INTO" || mode === "INSERT_AFTER_BEGIN") &&
      (wrapper === "PRESERVE_CLASS" || wrapper === "COMPLETE"))
    ? "SIMPLE"
    : wrapper
}

function createDomForInjection(content: string, wrapper: WrapMode | "COMPLETE", target: HTMLElement, divId: string) {
  const document = target.ownerDocument
  const container = wrapper === "CLONED" ? target.cloneNode(false) : document.createElement("div")
  container.setAttribute("id", divId)
  container.classList.add("nosto_element", "nosto-dynamic-placement")
  if (wrapper === "PRESERVE_CLASS") {
    container.className += ` ${target.className}`
  }
  container.innerHTML = content.trim()
  return wrapper === "UNWRAPPED" ? Array.from(container.childNodes) : [container]
}

export function injectDynamicCampaign(
  element: HTMLElement,
  config: DynamicPlacementDTO,
  contentToInject: string,
  divId: string
): [string, HTMLElement[]] {
  removeInjectedCampaign(divId)
  const { mode, wrapper } = config
  const validWrapper = validateWrapper(mode, wrapper)
  const replacement = createDomForInjection(contentToInject, validWrapper, element, divId)
  void selector.performDomOperation(mode, element, replacement, divId)
  manipulationState[divId] = Object.freeze({
    mode,
    // replace with either original or no elements
    original: mode === "REPLACE" ? [element] : [],
    replacement: wrapper === "UNWRAPPED" ? Object.freeze(replacement) : undefined
  })
  return [divId, replacement]
}

export async function updateElement(element: HTMLElement, mode: InsertMode, content: string | HTMLElement) {
  await selector.performOperation(mode, element, content)
}

export function injectedCampaigns() {
  return { ...manipulationState }
}
