import { errorMessage } from "@/utils/error"
import { isError } from "@/utils/isError"

export interface ErrorInstance extends Error {
  reporter?: string
  number?: number
  fileName?: string
  lineNumber?: number
  columnNumber?: number
}

/**
 * Concatenate the given arguments into a single message
 */
export function getMessage(args: unknown[]) {
  return args
    .map(arg => {
      if (isError(arg)) {
        return errorMessage(arg)
      } else if (typeof arg === "object") {
        return JSON.stringify(arg)
      }
      return arg
    })
    .filter(Boolean)
    .join(" ")
}

/**
 * Return an errors from the given arguments or create a new one
 */
export function getError(args: unknown[]): ErrorInstance {
  return args.find(isError) ?? new Error()
}

interface LoggingFlag {
  local?: true
  sampled?: true
}

export function isLoggingFlag(o: unknown): o is LoggingFlag {
  return !!(
    o &&
    typeof o === "object" &&
    (("local" in o && o.local === true) || ("sampled" in o && o.sampled === true))
  )
}
