import { Token } from "./parser"

export function needsFlattening({ type, children }: Token) {
  if (children) {
    return (
      children.some(needsFlattening) ||
      (type === "selector" && children.some(c => c.children)) ||
      (type === "at-rule" && children.some(c => c.type === "at-rule"))
    )
  }
  return false
}

function join(part1: string, part2: string) {
  return /^\w/.test(part1) ? `${part1}${part2}` : `${part2}${part1}`
}

function splitBy(selector: string, separator: string) {
  if (!selector.includes(`"`) && !selector.includes(`'`)) {
    return selector.split(separator)
  }
  const result = []
  let buffer = ""
  let quoteChar = ""

  for (const char of selector) {
    if (quoteChar) {
      if (char === quoteChar) {
        quoteChar = ""
      }
      buffer += char
    } else {
      if (char === '"' || char === "'") {
        quoteChar = char
        buffer += char
      } else if (char === separator) {
        result.push(buffer.trim())
        buffer = ""
      } else {
        buffer += char
      }
    }
  }

  if (buffer.trim()) {
    result.push(buffer.trim())
  }

  return result
}

function replaceParent(child: string, parent: string) {
  // wrap parent selector into a :is() pseudo class if it contains spaces
  const normalized = parent.includes(" ") ? `:is(${parent})` : parent
  if (child.includes(`"`) || child.includes(`'`)) {
    // TODO modify this function to not replace & inside quoted strings
  }
  return child.replace(/(\S+)?&(\w+)?/g, (_, m1, m2) => (m1 || m2 ? join(normalized, m1 || m2) : normalized))
}

export function combineSelectors(parent: string, child: string, commaCheck = true): string {
  const SIBLING = /^[+~]/g

  // child is a list of selectors
  if (commaCheck && child.includes(",")) {
    return splitBy(child, ",")
      .map(part => combineSelectors(parent, part.trim(), false))
      .join(", ")
  }
  // child contains explicit parent reference via &
  if (child.includes("&")) {
    return replaceParent(child, parent)
  }
  // child is a sibling selector
  if (SIBLING.test(child) && parent.includes(" ")) {
    return `:is(${parent}) ${child}`
  }
  // simple case where parent is just prepended
  return `${parent} ${child}`
}

function flattenSelector(parent: Token, child: Token) {
  return { ...child, value: combineSelectors(parent.value, child.value) }
}

function flattenAtRule(parent: Token, child: Token) {
  return {
    ...child,
    children: [...flatten({ ...parent, children: child.children })]
  }
}

/**
 * Flatten the token based on CSS Nesting rules
 * This is a polyfill for browsers that do not support CSS nesting.
 * It should be dropped from the client script once support is wider https://caniuse.com/css-nesting
 */
export function flatten(token: Token): Token[] {
  if (token.children) {
    const children = token.children.flatMap(flatten)
    if (token.type === "selector" && children.some(c => c.children)) {
      return children.reduce((acc, child) => {
        if (child.type === "selector") {
          acc.push(flattenSelector(token, child))
        } else if (child.type === "at-rule" && child.children) {
          acc.push(flattenAtRule(token, child))
        } else if (acc[acc.length - 1]?.type === "selector") {
          acc[acc.length - 1].children?.push(child)
        } else {
          acc.push({ ...token, children: [child] })
        }
        return acc
      }, [] as Token[])
    }
    return [{ ...token, children }]
  }
  return [token]
}
