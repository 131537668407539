import page from "@/core/page"
import parseAggregateRatings from "@/metadata/product/ratings/aggregateRating"
import parsePrice from "@/metadata/utils/price"
import text from "@/metadata/utils/text"
import innerHtml from "@/metadata/utils/innerHtml"
import { schemaFn, select, selectMulti, keyval } from "@/metadata/utils/schema"
import linkify from "@/metadata/product/url/linkify"
import { PushedProductSKU, PushedVariation } from "@/types"
import emptyToUndefined from "@/metadata/utils/empty"
import pipe from "@/utils/pipe"
import texts from "../utils/texts"
import toFloat from "../utils/toFloat"
import toInt from "../utils/toInt"
import { Product } from "@/core/tagging/types"
import settings from "@/core/settings"
import context from "@/core/context"

function mop(values: string[]) {
  return values ? emptyToUndefined(values.filter(value => value.trim().length)) : undefined
}

const skuFn = schemaFn<PushedProductSKU>({
  id: select(".id"),
  name: select(".name"),
  price: select(".price", pipe(text, parsePrice)),
  list_price: select(".list_price", pipe(text, parsePrice)),
  url: select(".url"),
  image_url: select(".image_url"),
  gtin: select(".gtin"),
  availability: select(".availability"),
  custom_fields: node => {
    return emptyToUndefined(keyval(".custom_fields > *")(node))!
  }
})

const variationFn = schemaFn<Partial<PushedVariation> & { variation_id: string }>({
  variation_id: select(".variation_id"),
  price: select(".price", pipe(text, parsePrice)),
  list_price: select(".list_price", pipe(text, parsePrice)),
  price_currency_code: select(".price_currency_code"),
  availability: select(".availability")
})

const productFn = schemaFn<Product>({
  url: select(
    ".nosto_product > .url",
    pipe(text, (v: string | undefined) => v || linkify())
  ),
  product_id: select(".nosto_product > .product_id"),
  selected_sku_id: select(".nosto_product > .selected_sku_id"),
  name: select(".nosto_product > .name"),
  image_url: select(".nosto_product > .image_url"),
  thumb_url: select(".nosto_product > .thumb_url"),
  price: select(".nosto_product > .price", pipe(text, parsePrice)),
  list_price: select(".nosto_product > .list_price", pipe(text, parsePrice)),
  price_currency_code: select(".nosto_product > .price_currency_code"),
  availability: select(".nosto_product > .availability"),
  brand: select(".nosto_product > .brand"),
  description: select(".nosto_product > .description", innerHtml),
  // @ts-expect-error field mismatch
  date_published: select(".nosto_product > .date_published"),
  valid_until: select(".nosto_product > .valid_until"),
  variation_id: select(".nosto_product > .variation_id"),
  review_count: select(".nosto_product > .review_count", pipe(text, toInt)),
  rating_value: select(".nosto_product > .rating_value", pipe(text, toFloat)),
  condition: select(".nosto_product > .condition"),
  gender: select(".nosto_product > .gender"),
  age_group: select(".nosto_product > .age_group"),
  gtin: select(".nosto_product > .gtin"),
  google_category: select(".nosto_product > .google_category"),
  unit_pricing_measure: select(".nosto_product > .unit_pricing_measure", pipe(text, toFloat)),
  unit_pricing_base_measure: select(".nosto_product > .unit_pricing_base_measure", pipe(text, toFloat)),
  unit_pricing_unit: select(".nosto_product > .unit_pricing_unit"),
  tags1: selectMulti(".nosto_product > .tags1 > .tag, .nosto_product > .tag1", pipe(texts, mop)),
  tags2: selectMulti(".nosto_product > .tags2 > .tag, .nosto_product > .tag2", pipe(texts, mop)),
  tags3: selectMulti(".nosto_product > .tags3 > .tag, .nosto_product > .tag3", pipe(texts, mop)),
  alternate_image_urls: selectMulti(
    ".nosto_product > .alternate_image_urls > .alternate_image_url, .nosto_product > .alternate_image_url",
    pipe(texts, mop)
  ),
  category: selectMulti(".nosto_product > .category, .nosto_product > .categories > .category", pipe(texts, mop)),
  category_id: selectMulti(".nosto_product > .category_id", pipe(texts, mop)),
  custom_fields: pipe(keyval(".nosto_product > .custom_fields > *"), emptyToUndefined),
  skus: node => {
    const elements = Array.from(node.querySelectorAll<HTMLElement>(".nosto_product .nosto_sku"))
    return emptyToUndefined(elements.map(skuFn))
  },
  variations: (node: ParentNode) => {
    const elements = Array.from(
      node.querySelectorAll<HTMLElement>(".nosto_product > .variations > .variation, .nosto_product > .variation")
    )
    const variations = elements.map(variationFn).filter(v => v.variation_id)
    const data = variations.reduce((map, variation) => ({ ...map, [variation.variation_id]: variation }), {})
    return emptyToUndefined(data)
  }
})

export default function findProducts() {
  const pDivs = page.selectAll(".nosto_product")
  const products = Array.from(pDivs).map(productFn).map(parseAggregateRatings)

  // augment product tagging with selected SKU ID from URL
  if (products.length && settings.skuParam) {
    const parameters = context.siteUrl.searchParams
    if (parameters.has(settings.skuParam)) {
      const skuId = parameters.get(settings.skuParam)!
      return products.map(product => ({ ...product, selected_sku_id: skuId }))
    }
  }
  return products
}
