export function deepEquals(a: unknown, b: unknown): boolean {
  if (a === b) {
    return true
  }
  if (typeof a !== "object" || a === null || typeof b !== "object" || b === null) {
    return false
  }
  if (Array.isArray(a) !== Array.isArray(b)) {
    return false
  }

  const keysA = Object.keys(a as object)
  const keysB = Object.keys(b as object)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEquals((a as object)[key], (b as object)[key])) {
      return false
    }
  }

  return true
}
