import staticPlacements from "./static"
import dynamicPlacements from "./dynamic"
import compositePlacements, { PlacementCampaign } from "./composite"
import { DynamicPlacementDTO, Equals, Expect } from "@/types"

const placements: Placements = compositePlacements(staticPlacements, dynamicPlacements)
export default placements

// explicitly defined to avoid inlining in typedoc
export interface Placements {
  getPlacements(): string[]
  initialBody(): null | HTMLElement
  injectCampaigns(campaigns: Record<string, string | PlacementCampaign>): {
    filledElements: string[]
    unFilledElements: string[]
  }
  isFiltered(placement: DynamicPlacementDTO): boolean
  isFilteringConfigured(placement: DynamicPlacementDTO): boolean
  removeContent(divId: string): void
  reset(): void
}

/** @hidden */
export type tests = [Expect<Equals<Placements, ReturnType<typeof compositePlacements>>>]
