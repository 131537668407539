import parseUri from "@/utils/parseuri"
import settings from "./settings"
import { DomainError } from "@/errors"

export function validateUrl(
  testUrl: string,
  anyDomain: boolean,
  site: string,
  subDomain: string,
  extraHosts: string[]
) {
  const testUrlParsed = parseUri(testUrl)
  if (anyDomain) {
    return true
  }
  const siteParsed = parseUri(site)
  if (testUrlParsed.hostname === siteParsed.hostname) {
    return true
  }
  if (subDomain) {
    if (testUrlParsed.hostname.endsWith(subDomain)) {
      return true
    }
  }
  if (extraHosts) {
    for (let i = 0; i < extraHosts.length; i += 1) {
      if (testUrlParsed.hostname.endsWith(parseUri(extraHosts[i]).hostname)) {
        return true
      }
    }
  }
  return false
}

export default function validateHost() {
  if (!validateUrl(window.location.href, settings.anyDomain, settings.site, settings.subDomain, settings.extraHosts)) {
    throw new DomainError(
      `The current domain ${window.location.host} isn't whitelisted for Nosto. For more information on how to whitelisting this domain, see https://support.nosto.com/faq/my-shops-domain-has-changed-how-to-proceed`
    )
  }
}
