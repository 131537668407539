import { AxiosInstance, AxiosRequestConfig } from "axios"
import visit from "@/core/visit"

export function modifyConfig(config: AxiosRequestConfig<unknown>) {
  if (visit.isDoNotTrack()) {
    config.headers = {
      ...(config.headers ?? {}),
      "X-Nosto-Optout": "1"
    }
  }
  return config
}

/**
 * Adds opt-out header to requests in case user opted out of tracking
 */
export default function addOptout(axios: AxiosInstance) {
  axios.interceptors.request.use(modifyConfig)
}
