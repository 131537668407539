export type TokenType = "selector" | "property" | "at-rule"

export interface Token {
  type: TokenType
  name?: string
  value: string
  children?: Token[]
}

function last<T>(arr: T[]): T {
  return arr[arr.length - 1]
}

function isWhitespace(char: string) {
  return char === " " || char === "\n" || char === "\t"
}

export function parseCSS(css: string): Token[] {
  const tokens: Token[][] = [[]]
  let buffer = ""

  function flushBuffer(type: TokenType) {
    if ((buffer = buffer.trim())) {
      if (type === "property") {
        const separator = buffer.indexOf(":")
        const name = buffer.slice(0, separator).trim()
        const value = buffer.slice(separator + 1).trim()
        last(tokens).push({ type, name, value })
      } else {
        last(tokens).push({ type, value: buffer })
      }
    }
    buffer = ""
  }

  function openScope() {
    tokens.push((last(last(tokens)).children = []))
  }

  function closeScope() {
    tokens.pop()
  }

  for (let i = 0; i < css.length; i++) {
    const char = css[i]

    // handle quotes
    if (char === '"' || char === "'") {
      const quote = char
      buffer += char
      i++
      while (css[i] !== quote) {
        buffer += css[i]
        i++
      }
      buffer += quote
      continue
    }

    // handle whitespace
    if (isWhitespace(char)) {
      while (isWhitespace(css[i + 1])) {
        i++
      }
      // collapse consecutive whitespace into a single space
      buffer += " "
      continue
    }

    // skip line comments
    if (char === "/" && css[i + 1] === "/") {
      i = css.indexOf("\n", i) + 1
      continue
    }

    // skip block comments
    if (char === "/" && css[i + 1] === "*") {
      i = css.indexOf("*/", i) + 2
      continue
    }

    // open scope
    if (char === "{") {
      flushBuffer(buffer.trim().startsWith("@") ? "at-rule" : "selector")
      openScope()
      continue
    }

    // close scope
    if (char === "}") {
      flushBuffer("property")
      closeScope()
      continue
    }

    // add at-rule or property
    if (char === ";") {
      flushBuffer(buffer.trim().startsWith("@") ? "at-rule" : "property")
      continue
    }

    buffer += char
  }

  // TODO flush remaining buffer ?

  return tokens[0]
}
