import createLoader from "@/core/loaders"
import createSelector from "@/placements/selector"
import windows from "@/core/windows"
import { EventResponseMessage } from "@/types"

const loaders = createLoader()
const selector = createSelector(windows.site.document, loaders)

export default (response: EventResponseMessage) => {
  if (response.js) {
    let body = windows.nosto.document.querySelector("body")
    // Sometimes the body is missing. In those cases, we can
    // just create it if missing. Unsure about the ramifications
    if (!body) {
      body = windows.nosto.document.createElement("body")
    }
    return selector.html(body, response.js)
  }
  return Promise.resolve()
}
